import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Select from "react-select";
import SvgComponent from "./SvgComponent";

const ButtonTextCompoenent = ({
  myInputValues2,
  myInputValues3,
  handleButtonTextChange,
  handleIconClick,
  showTooltipData,
  setTooltipContent,
  tooltipContent,
  ToolTipBox,
}) => {
  const [value, setValue] = useState();

  const colorOptions = [
    { value: "transparent", label: "Transparent" },
    { value: "#ff0000", label: "Red" },
    { value: "#00ff00", label: "Green" },
    { value: "#0000ff", label: "Blue" },
    { value: "#ffffff", label: "white" },
  ];
  const fixedStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
      borderRadius: "5px",
      width: "180px",
      border: "none",
      Height: "90px",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1a237e",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1a237e",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White", // Change placeholder color here
      fontSize: "12px",
    }),
  };

  return (
    <div>
      <div className="Button_Section">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Button Text</Accordion.Header>
            <Accordion.Body>
              <>
                <div className="inner_sec">
                  <textarea
                    className="input-part"
                    name="canvas_text2"
                    rows="2"
                    cols={36}
                    placeholder="continue...."
                    value={myInputValues2}
                    onChange={(e) => handleButtonTextChange(e)}
                  />
                  <p>
                    This input supports static text or data & variables through
                    the use of brackets, ie . For example, you could use "Hello"
                    or "https://reddit.com/r/ "
                  </p>
                </div>
                {/* <div className="main_icon-2">
                  <div className="space-1">
                    <div className="text-small-slt">
                      <p> Icon </p>
                    </div>
                    <div className="input-leftname">
                      <input type="text" value={""} name="est_item_size" />
                    </div>
                  </div>
                  <div className="space-1">
                    <div className="text-small-slt">
                      <p> Custom Icon </p>
                      <ToolTipBox
                        iconType="green"
                        type="Custom Icon"
                        handleIconClick={handleIconClick}
                        showTooltipData={showTooltipData}
                        tooltipContent={tooltipContent}
                      />{" "}
                    </div>
                    <div className="svg_space">
                      <div className="slt-type">
                        <Select
                          placeholder="Type a Color"
                          options={colorOptions}
                          styles={fixedStyles}
                        />
                      </div>

                      <SvgComponent />
                    </div>
                  </div>
                  <div className="space-1">
                    <div className="text-small-slt">
                      <p> Disabled </p>
                      <ToolTipBox
                        type="Disabled"
                        iconType="green"
                        handleIconClick={handleIconClick}
                        showTooltipData={showTooltipData}
                        tooltipContent={tooltipContent}
                      />{" "}
                    </div>
                    <div className="svg_space">
                      <div className="slt-type">
                        <Select
                          placeholder="Type a Color"
                          options={colorOptions}
                          styles={fixedStyles}
                        />
                      </div>

                      <SvgComponent />
                    </div>
                  </div>
                  <div className="space-1">
                    <div className="text-small-slt">
                      <p> Loading </p>
                      <ToolTipBox
                        iconType="green"
                        type="Loading"
                        handleIconClick={handleIconClick}
                        showTooltipData={showTooltipData}
                        tooltipContent={tooltipContent}
                      />{" "}
                    </div>
                    <div className="svg_space">
                      <div className="slt-type">
                        <Select
                          placeholder="Type a Color"
                          options={colorOptions}
                          styles={fixedStyles}
                        />
                      </div>

                      <SvgComponent />
                    </div>
                  </div>
                </div> */}
              </>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default ButtonTextCompoenent;
