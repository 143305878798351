import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import info_icon from "../../../assets/Icons/info_icon.svg";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import {
  setFolderStructure,
  setSelectedFolder,
} from "../../../redux/actions/defaultActions";
import UpdateMyValues from "../../../common/customHooks/UpdateMyValues";
import BasicComponent from "./CommonRightbarTab/BasicComponent";
import AccessiblityComponent from "./CommonRightbarTab/AccessiblityComponent";
import AdvancedComponent from "./CommonRightbarTab/AdvancedComponent";
import WebSpecificComponent from "./CommonRightbarTab/WebSpecificComponent";
import ToolTipBox from "../../../ToolTip/ToolTipBox";

const SecondTab = ({ setShowTooltipData, showTooltipData }) => {
  const { selectedFolder, folderStructure } = useSelector(
    (state) => state.default
  );

  const dispatch = useDispatch();

  // const ReverseSwitch = () => {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [datePickerKeyBoard, setDatePickerKeyBoard] = useState(false);
  const [bounce, setBounced] = useState(false);
  const [reduceMotion, setReduceMotion] = useState(false);
  const [dropdownKeyboard, setDropDownKeyboard] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [tooltipContent, setTooltipContent] = useState();
  const [selectedOperator, setSelectedOperator] = useState();
  const [gridColumValue, setGridColumValue] = useState(
    selectedFolder?.gridTemplateColumns && selectedFolder?.gridTemplateColumns
  );

  const handleColumnChange = (e, types) => {
    setChecked(e);

    if (e === true) {
      handleStyleUpdate(folderStructure, selectedFolder, "direction", "row");
    } else {
      handleStyleUpdate(folderStructure, selectedFolder, "direction", "column");
    }
  };
  const handleIconClick = (e, type) => {
    e.stopPropagation();

    if (type === tooltipContent) {
      setShowTooltipData(false);
      setTooltipContent();
    } else {
      setTooltipContent(type);
      setShowTooltipData(true);
    }
  };

  const [settingBar, setSettingBar] = useState("set-2");
  const [folderValue, setFolderValue] = useState({});
  const [modeNative, setModeNative] = useState("native");
  const handleChangeTab = (type) => {
    setSettingBar(type);
  };

  useEffect(() => {
    if (selectedFolder) {
      setFolderValue(selectedFolder);
    }
  }, [selectedFolder]);

  const updateNameRecursively = (folders, folderId, newName) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, name: newName };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateNameRecursively(
          folder.children,
          folderId,
          newName
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const handleSave = (e) => {
    const { name, value } = e.target;
    handleStyleUpdate(folderStructure, selectedFolder, name, value);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setFolderValue({ ...folderValue, name: value });
  };

  const setSelectedFolderAction = (folder) =>
    dispatch(setSelectedFolder(folder));

  const setFolderStructureAction = (structure) =>
    dispatch(setFolderStructure(structure));

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  const updateParentAlign = (folders, folderId, data) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return { ...folder, display: data };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateParentAlign(
          folder.children,
          folderId,
          data
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  let mydatas = "";

  const myFunction = (folder, folderId) => {
    // eslint-disable-next-line array-callback-return
    folder.find((folderItem) => {
      if (folderItem.id === folderId) {
        mydatas = folderItem.display;
      } else if (folderItem.children && folderItem.children.length > 0) {
        mydatas = myFunction(folderItem.children, folderId);
      }
    });
    return mydatas;
  };

  if (mydatas === "") {
    myFunction(folderStructure, selectedFolder?.parentId);
  }

  // const updateValue = (folders, folderId, names, newValue) => {
  //   return folders.map((folder) => {
  //     if (folder.flashUniqueId === folderId) {
  //       return {FV
  //         ...folder,
  //         names: newValue,
  //       };
  //     } else if (folder.children && folder.children.length > 0) {
  //       const updatedChildren = updateValue(
  //         folder.children,
  //         folderId,
  //         names,
  //         newValue
  //       );
  //       return { ...folder, children: updatedChildren };
  //     }
  //     return folder;
  //   });
  // };

  const updateValue = (folders, folderId, propertyName, newValue) => {
    return folders.map((folder) => {
      if (folder.flashUniqueId === folderId) {
        return {
          ...folder,
          [propertyName]: newValue,
        };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValue(
          folder.children,
          folderId,
          propertyName,
          newValue
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const updateValue2 = (
    folders,
    folderId,
    propertyName1,
    propertyName2,
    newValue1,
    newValue2
  ) => {
    return folders.map((folder) => {
      if (folder.id === folderId) {
        return {
          ...folder,
          display: newValue1,
          gridTemplateColumns: newValue2,
        };
      } else if (folder.children && folder.children.length > 0) {
        const updatedChildren = updateValue2(
          folder.children,
          folderId,
          propertyName1,
          propertyName2,
          newValue1,
          newValue2
        );
        return { ...folder, children: updatedChildren };
      }
      return folder;
    });
  };

  const handleDisplayGrid = (e) => {
    const gridColumn = e.target.value;

    setGridColumValue(gridColumn);

    if (selectedFolder) {
      if (selectedFolder && selectedFolder?.type === "flashlist") {
        const updatedSelectedFolder = {
          ...selectedFolder,
          gridColumn: gridColumn,
        };

        const updatedFolderStructure = updateValue2(
          folderStructure,
          selectedFolder.id,
          "display",
          "gridTemplateColumns",
          "flex",
          gridColumn
        );
        setFolderStructureAction(updatedFolderStructure);
        setSelectedFolderAction(updatedSelectedFolder);
      }
    }
  };

  const handleme = () => {
    if (mydatas === "flex") {
      const updatedFolderStructure = updateParentAlign(
        folderStructure,
        selectedFolder.parentId,
        "grid"
      );
      setFolderStructureAction(updatedFolderStructure);
    }
  };

  const handleDisplayGridRow = (e) => {
    const gridRow = e.target.value;

    if (selectedFolder) {
      if (selectedFolder && selectedFolder?.flashUniqueId) {
        const updatedSelectedFolder = {
          ...selectedFolder,
          gridRow: gridRow,
        };

        const updatedFolderStructure = updateValue(
          folderStructure,
          selectedFolder.flashUniqueId,
          "gridRow",
          gridRow
        );

        setFolderStructureAction(updatedFolderStructure);
        setSelectedFolderAction(updatedSelectedFolder);
      } else {
        handleStyleUpdate(folderStructure, selectedFolder, "gridRow", gridRow);
      }
    }
  };

  const estSizeChangeHandler = (e) => {
    const { name, value } = e.target;
    handleStyleUpdate(folderStructure, selectedFolder, name, value);
  };

  const dateTimeTypeChangeHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(folderStructure, selectedFolder, "mode", data);
  };
  const dropModeChangeHandler = (e) => {
    const data = e.target?.value;
    handleStyleUpdate(folderStructure, selectedFolder, "mode", data);
    // setModeNative("native");
  };
  const resizeImageChangeHandler = (e) => {
    const data = e.target?.value;
    handleStyleUpdate(folderStructure, selectedFolder, "resize", data);
  };
  const changeTouchEventHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "allowTouchEvents",
      data
    );
  };
  const formatChangeHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(folderStructure, selectedFolder, "format", data);
  };
  const onAssitiveHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(folderStructure, selectedFolder, "assitive", data);
  };
  const onPlaceHolderHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(folderStructure, selectedFolder, "placeholder", data);
  };
  const onApprearanceHandler = (e) => {
    const data = e.target.value;
    handleStyleUpdate(folderStructure, selectedFolder, "borderStyle", data);
  };

  const handleSwipeClick = (e, types, dataKey) => {
    // setChecked(!checked);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      dataKey,
      e ? types : "unset"
    );
  };

  return (
    <>
      <div className="Sec-bar">
        <div className="sec-inner">
          <BasicComponent
            folderValue={folderValue}
            handleChange={handleChange}
            handleSave={handleSave}
            selectedFolder={selectedFolder}
            estSizeChangeHandler={estSizeChangeHandler}
            setChecked={setChecked}
            setChecked2={setChecked2}
            setChecked3={setChecked3}
            checked={checked}
            checked2={checked2}
            checked3={checked3}
            handleColumnChange={handleColumnChange}
            gridColumValue={gridColumValue}
            handleme={handleme}
            handleDisplayGrid={handleDisplayGrid}
            handleDisplayGridRow={handleDisplayGridRow}
            selectedCategorie={selectedCategorie}
            setSelectedCategorie={setSelectedCategorie}
            dateTpye="datepicker"
            buttonType="buttonContent"
            tabViewType="tabView"
            tabDropdownType="dropdown"
            tabViewIndex="tabviewitem"
            dateTimeTypeChangeHandler={dateTimeTypeChangeHandler}
            dropModeChangeHandler={dropModeChangeHandler}
            resizeImageChangeHandler={resizeImageChangeHandler}
            modeNative={modeNative}
            formatChangeHandler={formatChangeHandler}
            onApprearanceHandler={onApprearanceHandler}
            onAssitiveHandler={onAssitiveHandler}
            onPlaceHolderHandler={onPlaceHolderHandler}
            handleIconClick={handleIconClick}
            showTooltipData={showTooltipData}
            setTooltipContent={setTooltipContent}
            tooltipContent={tooltipContent}
            ToolTipBox={ToolTipBox}
            handleSwipeClick={handleSwipeClick}
            changeTouchEventHandler={changeTouchEventHandler}
            setDatePickerKeyBoard={setDatePickerKeyBoard}
            datePickerKeyBoard={datePickerKeyBoard}
            bounce={bounce}
            reduceMotion={reduceMotion}
            setBounced={setBounced}
            setReduceMotion={setReduceMotion}
            setDropDownKeyboard={setDropDownKeyboard}
            dropdownKeyboard={dropdownKeyboard}
          />

          {/* {selectedFolder?.type === "tabviewitem" && (
            <AccessiblityComponent
              handleIconClick={handleIconClick}
              showTooltipData={showTooltipData}
              setTooltipContent={setTooltipContent}
              tooltipContent={tooltipContent}
              ToolTipBox={ToolTipBox}
            />
          )} */}
          {selectedFolder?.type === "bottomSheets" ||
            (selectedFolder?.type === "textField" && (
              <AdvancedComponent
                handleIconClick={handleIconClick}
                showTooltipData={showTooltipData}
                setTooltipContent={setTooltipContent}
                tooltipContent={tooltipContent}
                ToolTipBox={ToolTipBox}
              />
            ))}
          {selectedFolder?.type === "textField" && (
            <WebSpecificComponent
              handleIconClick={handleIconClick}
              showTooltipData={showTooltipData}
              setTooltipContent={setTooltipContent}
              tooltipContent={tooltipContent}
              ToolTipBox={ToolTipBox}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SecondTab;
