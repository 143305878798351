import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const DropDown = ({
  options,
  onSelect,
  className,
  uploadDropdownHandler,
  item,
  isDraftPreview,
}) => {
  const handleOptionSelect = (value) => {
    onSelect(value);
  };

  const width = parseInt(item.size[0].width);
  const height = parseInt(item.size[1].height);
  const min_height = parseInt(item.size[3]?.min_height);
  const min_width = parseInt(item.size[2]?.min_width);
  const max_height = parseInt(item.size[5]?.max_height);
  const max_width = parseInt(item.size[4]?.max_width);
  const widthUnit = item.size[0]?.unit;
  const heightUnit = item.size[1]?.unit;
  const min_heightunit = item.size[3]?.unit;
  const min_widthunit = item.size[2]?.unit;
  const max_heightunit = item.size[5]?.unit;
  const max_widthunit = item.size[4]?.unit;

  const marginStyle = item.margin
    ? {
        margin: item.margin[0].all + item.margin[0].unit,
        marginLeft: item.margin[1].left + item.margin[1].unit,
        marginTop: item.margin[2].top + item.margin[2].unit,
        marginRight: item.margin[3].right + item.margin[3].unit,
        marginBottom: item.margin[4].bottom + item.margin[4].unit,
      }
    : {};
  const paddingStyle = item.padding
    ? {
        padding: item.padding[0].all + item.padding[0].unit,
        paddingLeft: item.padding[1].left + item.padding[1].unit,
        paddingTop: item.padding[2].top + item.padding[2].unit,
        paddingRight: item.padding[3].right + item.padding[3].unit,
        paddingBottom: item.padding[4].bottom + item.padding[4].unit,
      }
    : {};
  const RadiusStyle = item.borderRadius
    ? {
        borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
        borderRadiusLeft:
          item.borderRadius[1].topLeft + item.borderRadius[1].unit,
        borderRadiusTop:
          item.borderRadius[2].topRight + item.borderRadius[2].unit,
        borderRadiusRight:
          item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
        borderRadiusBottom:
          item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
      }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
        borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
        borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1].unit,
        borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
        borderRightWidth: item.borderWidth[3].right + item.borderWidth[3].unit,
        borderBottomWidth:
          item.borderWidth[4].bottom + item.borderWidth[4].unit,
      }
    : {};

  return (
    <>
      <div
        className={`dropdown-options ${className}`}
        style={{
          backgroundColor: item.id
            ? item?.btndisabled === "true"
              ? "#9295a899"
              : item?.background
            : null,
          resize: "none",
          zIndex: "10",
          cursor: isDraftPreview ? "pointer" : "default",
          width: `${width}${widthUnit}`,
          height: `${height}${heightUnit}`,
          minHeight: `${min_height}${min_heightunit}`,
          minWidth: `${min_width}${min_widthunit}`,
          maxHeight: `${max_height}${max_heightunit}`,
          maxWidth: `${max_width}${max_widthunit}`,
          borderColor: item.id
            ? item?.errors
              ? "#f01111"
              : item.borderColor
            : null,
          borderStyle: item.id ? item.borderStyle : null,
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          ...RadiusStyle,
          cursor: "pointer",
        }}
      >
        <label>{item?.label}</label>
        {item?.leftIcon && (
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={item?.leftIcon} style={{ fontSize: 18 }} />
          </span>
        )}
        <select
          name="dropdown"
          id="dropdown"
          onClick={(e) => uploadDropdownHandler(e, item)}
          disabled={item?.btndisabled}
          style={{
            color: item.id ? item.color : null,
            fontSize: item.id ? `${item.fosntSize}px` : "12px",
            lineHeight: item.id ? `${item.lineHeight}px` : "12px",
            letterSpacing: item.id ? `${item.letterSpacing}px` : null,
            fontFamily: item.id ? item.fontFamily : null,
            textAlign: item.id ? item.textAlign : null,
            fontWeight:
              item.id && item.fontWeight.length > 0
                ? item.fontWeight.find((obj) => obj.selectedFont)?.Weight
                : null,
            textDecoration: item.id ? item.textdecoration : null,
            textTransform: item.id ? item.textTransform : null,
          }}
        >
          <option hidden>
            {" "}
            {item?.placeholder ? item.placeholder : "select a option"}
          </option>
          {Array.isArray(item?.dropdownOptions) &&
            item?.dropdownOptions?.map((data) => (
              <option value={data?.value}>{data?.label || data?.name}</option>
            ))}
        </select>
        {item?.rightIcon && (
          <span style={{ marginLeft: "8px" }}>
            <FontAwesomeIcon icon={item?.rightIcon} style={{ fontSize: 18 }} />
          </span>
        )}
      </div>
      <span style={{ color: "#141a2f" }}>{item?.assitive}</span>
    </>
  );
};

export default DropDown;
