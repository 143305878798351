import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "react-responsive-modal";
import dayjs from "dayjs";
import { PickersActionBar } from "@mui/x-date-pickers/PickersActionBar";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

// const theme = createTheme({
//   components: {
//     MuiPickersDay: {
//       styleOverrides: {
//         root: {
//           "&.Mui-selected": {
//             backgroundColor: "#rgb(25, 118, 210)",
//             "&:hover": {
//               backgroundColor: "#388E3C",
//             },
//           },
//         },
//       },
//     },
//     MuiPickersToolbar: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "#rgb(25, 118, 210)",
//           color: "#fff",
//         },
//       },
//     },
//     MuiPickersClock: {
//       styleOverrides: {
//         pin: {
//           backgroundColor: "#rgb(25, 118, 210)",
//         },
//         amButton: {
//           color: "#rgb(25, 118, 210)",
//         },
//         pmButton: {
//           color: "#4CAF50",
//         },
//       },
//     },
//     MuiPickersClockNumber: {
//       styleOverrides: {
//         clockNumberSelected: {
//           color: "#fff",
//           backgroundColor: "#4CAF50",
//         },
//       },
//     },
//     MuiButtonBase: {
//       styleOverrides: {
//         root: {
//           color: "#4CAF50",
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         textPrimary: {
//           color: "#4CAF50",
//         },
//       },
//     },
//   },
// });

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#rgb(25, 118, 210)",
            "&:hover": {
              backgroundColor: "#rgb(25, 118, 210)",
            },
          },
        },
      },
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#rgb(25, 118, 210)",
          color: "#000000",
        },
      },
    },
    MuiPickersClock: {
      styleOverrides: {
        pin: {
          backgroundColor: "#rgb(25, 118, 210)",
        },
        amButton: {
          color: "#rgb(25, 118, 210)",
        },
        pmButton: {
          color: "#rgb(25, 118, 210)",
        },
      },
    },
    MuiPickersClockNumber: {
      styleOverrides: {
        clockNumberSelected: {
          color: "#000000",
          backgroundColor: "#rgb(25, 118, 210)",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#rgb(25, 118, 210)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: "#rgb(25, 118, 210)",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          "& .MuiButton-root": {
            display: "none",
          },
        },
      },
    },
  },
});

const ResponsiveDateTimePickers = ({
  onClose,
  open,
  data,
  item,
  selectedFolder,
  disabled,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    selectedFolder?.dateData ? dayjs(selectedFolder.dateData) : dayjs()
  );

  const handleDateChange = (newDate, type) => {
    if (newDate && newDate.isValid()) {
      setSelectedDate(newDate);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleOk = () => {
    onClose();
    data(selectedDate.toISOString());
  };

  return (
    <>
      <>
        {selectedFolder?.mode === "date" && (
          <Modal
            open={open}
            onClose={onClose}
            className="datepickermodal"
            key={item?.id}
            center
            disabled
          >
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={(event) => event.stopPropagation()}
                >
                  <StaticDatePicker
                    displayStaticWrapperAs="mobile"
                    label="Select Date"
                    value={selectedDate}
                    onChange={(values) => handleDateChange(values, "date")}
                    renderInput={(params) => <TextField {...params} />}
                    componentsProps={{
                      actionBar: {
                        style: { display: "none" },
                      },
                    }}
                  />
                  <Box mt={2}>
                    <Button variant="contained" onClick={handleOk}>
                      OK
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      style={{ marginLeft: 8 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </LocalizationProvider>
            </ThemeProvider>
          </Modal>
        )}
      </>
      <>
        {selectedFolder?.mode === "time" && (
          <Modal
            open={open}
            onClose={onClose}
            className="datepickermodal"
            key={item?.id}
            center
          >
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={(event) => event.stopPropagation()}
                >
                  <StaticTimePicker
                    ampm={false}
                    label="Select Time"
                    value={selectedDate}
                    onChange={(event, values) =>
                      handleDateChange(event, values, "time")
                    }
                    renderInput={(params) => <TextField {...params} />}
                    componentsProps={{
                      actionBar: {
                        style: { display: "none" },
                      },
                    }}
                  />
                  <Box mt={2}>
                    <Button variant="contained" onClick={handleOk}>
                      OK
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      style={{ marginLeft: 8 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </LocalizationProvider>
            </ThemeProvider>
          </Modal>
        )}
      </>
      <>
        {selectedFolder?.mode === "dateTime" && (
          <Modal
            open={open}
            onClose={onClose}
            className="datepickermodal"
            key={item?.id}
            center
          >
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={(event) => event.stopPropagation()}
                >
                  <StaticDateTimePicker
                    displayStaticWrapperAs="mobile"
                    label="Select Date and Time"
                    value={selectedDate}
                    onChange={(values) => handleDateChange(values, "dateTime")}
                    renderInput={(params) => <TextField {...params} />}
                    componentsProps={{
                      actionBar: {
                        style: { display: "none" },
                      },
                    }}
                  />

                  <Box mt={2}>
                    <Button variant="contained" onClick={handleOk}>
                      OK
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      style={{ marginLeft: 8 }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </LocalizationProvider>
            </ThemeProvider>
          </Modal>
        )}
      </>
    </>
  );
};

export default ResponsiveDateTimePickers;
