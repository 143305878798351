import React, { useState } from "react";
import info_icon from "../../../../assets/Icons/info_icon.svg";
import Accordion from "react-bootstrap/Accordion";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import Select from "react-select";
import SvgComponent from "./SvgComponent";
import JsonTextEditor from "../TextAreaEditor/JsonTextEditor";
import ModalEditor from "../FunctionComponent/ModalEditor";
import iconconfiguration from "../../../../assets/Icons/iconconfiguration.svg";
import { Configuration } from "./Configuration";

const DateInputsandVariables = ({
  jsonObject,
  myStyle,
  jsonInput,
  currentEditObj,
  theme,
  radioType,
  buttonRowType,
  tabViewType,
  tabViewIndex,
  ToolTipBox,
  tooltipContent,
  setTooltipContent,
  showTooltipData,
  handleIconClick,
  onLabelChange,
  onDisabledChange,
  onIndexTabChange,
  onErrorChange,
  setLabelValue,
  labelValue,
  indexValue,
  selectedFolder,
  folderStructure,
  onJsonOptionChangeHandler,
  onDisabledButtonChange,
  iconLeftClickHandler,
  // iconRightClickHandler,
  optionIcon,
  optionNameIcon,
  optionTabIcon,
  onTitleChangeHandler,
  iconClickHandler,
  tabDropdownIndex,
  optionLeftIcon,
  optionRightIcon,
  setOpen,
  open,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [jsonText, setJsonText] = useState("");
  const [text, setText] = useState("");
  const onOpenModal = (data) => setOpen(data);
  const onCloseModal = () => setOpen(false);
  const colorOptions = [
    { value: "transparent", label: "Transparent" },
    { value: "#ff0000", label: "Red" },
    { value: "#00ff00", label: "Green" },
    { value: "#0000ff", label: "Blue" },
    { value: "#ffffff", label: "white" },
  ];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "180px",
      border: "none",
      height: "10px",
      color: "#ffff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White",
      fontSize: "12px",
    }),
  };
  const fixedStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "180px",
      border: "none",
      height: "10px",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White",
      fontSize: "12px",
    }),
  };

  const openModal = (data) => {
    setModalIsOpen(true);
    setJsonText("");
    setText("");
    setModalData(data);
  };
  const closeModalEditor = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div>
        <div className="main-bar">
          <div className="sec-one">
            <div className="scroll-main">
              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Data Inputs & Variables</Accordion.Header>
                  <Accordion.Body>
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") && (
                      <>
                        <div className="space-1">
                          <div className="text-upper">
                            <p>Data Source</p>
                            <ToolTipBox
                              type="Data Source"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-type">
                            <Select
                              placeholder=""
                              // options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        <div className="space-1">
                          <div className="text-upper">
                            <SubdirectoryArrowRightIcon />
                            <p>transform</p>
                          </div>
                          <div className="slt-type">
                            <Select
                              placeholder=""
                              // options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="bottom_line"></div>

                        <div className="Custom-1">
                          <div className="snap_img">
                            <p>Custom Snap</p>
                            <ToolTipBox
                              type="Custom Snap"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <Select
                              options={colorOptions}
                              name="colors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={fixedStyles}
                              placeholder="Select..."
                            />
                            <SvgComponent onClick={() => openModal} />
                          </div>
                        </div>
                      </>
                    )}
                    {selectedFolder?.type === "datepicker" ? (
                      <>
                        <div className="space-1">
                          <div className="text-upper">
                            <p>Data Source</p>
                            <ToolTipBox
                              type="Data Source"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-type">
                            <Select
                              placeholder=""
                              // options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-upper">
                            <p>initial Value</p>
                            <ToolTipBox
                              type="initial Value"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-type">
                            <Select
                              placeholder=""
                              // options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>

                        {/* <div className="space-1">
                          <div className="text-upper">
                            <SubdirectoryArrowRightIcon />
                            <p>transform</p>
                          </div>
                          <div className="slt-type">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div> */}
                        <div className="bottom_line"></div>
                      </>
                    ) : null}

                    {selectedFolder?.type === "dropdown" ? (
                      <>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Label</p>
                            <ToolTipBox
                              type="Label"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                options={
                                  labelValue &&
                                  labelValue?.map((data) => ({
                                    value: data.name,
                                    label: data.name,
                                  }))
                                }
                                name="varLabel"
                                value={labelValue}
                                onChange={onLabelChange}
                                placeholder="Type a label"
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent onClick={() => openModal("label")} />
                          </div>
                        </div>

                        <div className="option_space">
                          <div className="text-small-slt">
                            <p>Options</p>
                            <ToolTipBox
                              iconType="green"
                              type="Options"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent
                              onClick={() => openModal("dropdownOptions")}
                            />
                          </div>
                        </div>

                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Disabled</p>
                            <ToolTipBox
                              iconType="green"
                              type="Disabled"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                options={[
                                  {
                                    value: "",
                                    label: "",
                                  },
                                ]}
                                name="varDisabled"
                                value={[
                                  {
                                    value: selectedFolder?.btndisabled,
                                    label: selectedFolder?.btndisabled,
                                  },
                                ]}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent
                              onClick={() => openModal("btndisabled")}
                            />
                          </div>
                        </div>
                        <div className="Error-piker">
                          <div className="text-small-slt">
                            <p>Error</p>
                            <ToolTipBox
                              iconType="green"
                              type="Error"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent onClick={() => openModal("errors")} />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {selectedFolder?.type === "bottomSheets" ? (
                      <>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Custom Snap points</p>
                            <ToolTipBox
                              type="Custom Snap points"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                // options={
                                //   labelValue &&
                                //   labelValue?.map((data) => ({
                                //     value: data.name,
                                //     label: data.name,
                                //   }))
                                // }
                                name="varLabel"
                                value={labelValue}
                                onChange={onLabelChange}
                                placeholder=""
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent onClick={() => openModal("label")} />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {selectedFolder?.type === "datepicker" ? (
                      <>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Label</p>
                            <ToolTipBox
                              type="Label"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                options={[
                                  {
                                    value: "",
                                    label: "",
                                  },
                                ]}
                                name="varLabel"
                                value={[
                                  {
                                    value: selectedFolder?.label,
                                    label: selectedFolder?.label,
                                  },
                                ]}
                                onChange={onLabelChange}
                                // placeholder="Type a label"
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent onClick={() => openModal("label")} />
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Minimum date</p>
                            <ToolTipBox
                              type="Minimum date"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Maximum date</p>
                            <ToolTipBox
                              type="Maximum date"
                              iconType="green"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Disabled</p>
                            <ToolTipBox
                              iconType="green"
                              type="Disabled"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                options={[
                                  {
                                    value: "",
                                    label: "",
                                  },
                                ]}
                                name="varDisabled"
                                value={[
                                  {
                                    value: selectedFolder?.buttondisable,
                                    label: selectedFolder?.buttondisable,
                                  },
                                ]}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent
                              onClick={() => openModal("buttondisable")}
                            />
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt" type="leftIcon">
                            <p>Left icon..</p>
                            <ToolTipBox
                              iconType="green"
                              type="Left icon name"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-para-flex">
                            <span
                              className="icon_config"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Select
                                name={optionLeftIcon}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={customStyles}
                                value={[
                                  {
                                    value: selectedFolder?.leftIcon?.iconName,
                                    label: selectedFolder?.leftIcon?.iconName,
                                  },
                                ]}
                              />
                              <img
                                src={iconconfiguration}
                                alt=""
                                width="5%"
                                onClick={() => onOpenModal("leftIcon")}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt" type="rightIcon">
                            <p>Right icon..</p>
                            <ToolTipBox
                              iconType="green"
                              type="Right icon name"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-para-flex">
                            <span
                              className="icon_config"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Select
                                name={optionRightIcon}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={customStyles}
                                value={[
                                  {
                                    value: selectedFolder?.rightIcon?.iconName,
                                    label: selectedFolder?.rightIcon?.iconName,
                                  },
                                ]}
                              />
                              <img
                                src={iconconfiguration}
                                alt=""
                                width="5%"
                                onClick={() => onOpenModal("rightIcon")}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                    {selectedFolder.type === "tabviewitem" &&
                      selectedFolder.type !== "tabView" && (
                        <>
                          <div className="space-1">
                            <div className="text-small-slt">
                              <p>Title</p>
                              <ToolTipBox
                                type="Title"
                                iconType="green"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>
                            <div className="svg_space">
                              <div className="slt-type">
                                <Select
                                  options={[
                                    {
                                      value: "",
                                      label: "",
                                    },
                                  ]}
                                  // options={
                                  //   labelValue &&
                                  //   labelValue?.map((data) => ({
                                  //     value: data.name,
                                  //     label: data.name,
                                  //   }))
                                  // }
                                  name="varTitle"
                                  value={[
                                    {
                                      value: selectedFolder?.title,
                                      label: selectedFolder?.title,
                                    },
                                  ]}
                                  onChange={onTitleChangeHandler}
                                  placeholder="title"
                                  styles={fixedStyles}
                                />
                              </div>
                              <SvgComponent
                                onClick={() => openModal("title")}
                              />
                            </div>
                          </div>
                          <div className="space-1">
                            <div className="text-small-slt" type="leftIcon">
                              <p>Icon</p>
                              <ToolTipBox
                                type="Icon"
                                iconType="green"
                                handleIconClick={handleIconClick}
                                showTooltipData={showTooltipData}
                                tooltipContent={tooltipContent}
                              />{" "}
                            </div>

                            <div className="input-leftname">
                              {" "}
                              <input
                                type="text"
                                options={optionTabIcon}
                                name="tabIcon"
                                value={selectedFolder?.tabIcon?.iconName}
                              />
                            </div>
                            <img
                              src={iconconfiguration}
                              alt=""
                              width="5%"
                              onClick={() => onOpenModal("tabIcon")}
                            />
                          </div>
                        </>
                      )}
                    {selectedFolder.type === "tabview" ? (
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Initial tab index</p>
                          <ToolTipBox
                            iconType="green"
                            type="Initial tab index"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              options={[
                                {
                                  value: "",
                                  label: "",
                                },
                              ]}
                              // options={
                              //   indexValue &&
                              //   indexValue?.map((data) => ({
                              //     value: data.name,
                              //     label: data.name,
                              //   }))
                              // }
                              name="varIndex"
                              value={[
                                {
                                  value: selectedFolder?.indexTab,
                                  label: selectedFolder?.indexTab,
                                },
                              ]}
                              onChange={onIndexTabChange}
                              placeholder="select..."
                              styles={fixedStyles}
                            />
                          </div>
                          <SvgComponent onClick={() => openModal("indexTab")} />
                        </div>
                      </div>
                    ) : null}

                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") &&
                      (radioType = "radiobutton" ? (
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>value</p>
                            <ToolTipBox
                              iconType="green"
                              type="value"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                      ) : null)}
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") && (
                      <>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Minimum date</p>
                            <ToolTipBox
                              type="Minimum date"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>

                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Editable</p>
                            <ToolTipBox
                              type="Editable"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder=""
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>

                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Left icon name</p>
                            <ToolTipBox
                              type="Left icon name"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="input-leftname">
                            <input
                              type="text"
                              value={""}
                              name="est_item_size"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") &&
                      (radioType = "radiobutton" ? (
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Selected Icon</p>
                            <ToolTipBox
                              type="Selected Icon"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="input-leftname">
                            <input
                              type="text"
                              value={""}
                              name="est_item_size"
                            />
                          </div>
                        </div>
                      ) : null)}
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") &&
                      (radioType = "radiobutton" ? (
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Custom Selected</p>
                            <ToolTipBox
                              type="Custom Selected"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                // placeholder="Type a Color"
                                // options={colorOptions}
                                styles={fixedStyles}
                              />
                            </div>
                            <SvgComponent />
                          </div>
                        </div>
                      ) : null)}
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") &&
                      (radioType = "radiobutton" ? (
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Unselected Icon</p>
                            <ToolTipBox
                              type="Unselected Icon"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="input-leftname">
                            <input
                              type="text"
                              value={""}
                              name="est_item_size"
                            />
                          </div>
                        </div>
                      ) : null)}
                    {(tabViewType !== "tabView" ||
                      tabViewIndex !== "tabviewitem") && (
                      <div className="space-1">
                        <div className="text-small-slt">
                          <p>Custom Unselected</p>
                          <ToolTipBox
                            type="Custom Unselected"
                            handleIconClick={handleIconClick}
                            showTooltipData={showTooltipData}
                            tooltipContent={tooltipContent}
                          />{" "}
                        </div>
                        <div className="svg_space">
                          <div className="slt-type">
                            <Select
                              // placeholder="Type a Color"
                              // options={colorOptions}
                              styles={fixedStyles}
                            />
                          </div>
                          <SvgComponent />
                        </div>
                      </div>
                    )}

                    {selectedFolder.type == "dropdown" ? (
                      <>
                        <div className="space-1">
                          <div className="text-small-slt" type="leftIcon">
                            <p>Left icon..</p>
                            <ToolTipBox
                              iconType="green"
                              type="Left icon name"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div
                            className="slt-para-flex"
                            // style={{ paddingBottom: "10px" }}
                          >
                            <span
                              className="icon_config"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Select
                                name={optionLeftIcon}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={customStyles}
                                value={[
                                  {
                                    value: selectedFolder?.leftIcon?.iconName,
                                    label: selectedFolder?.leftIcon?.iconName,
                                  },
                                ]}
                              />
                              <img
                                src={iconconfiguration}
                                alt=""
                                width="5%"
                                onClick={() => onOpenModal("leftIcon")}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt" type="rightIcon">
                            <p>Right icon..</p>
                            <ToolTipBox
                              iconType="green"
                              type="Right icon name"
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="slt-para-flex">
                            <span
                              className="icon_config"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Select
                                name={optionRightIcon}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                styles={customStyles}
                                value={[
                                  {
                                    value: selectedFolder?.rightIcon?.iconName,
                                    label: selectedFolder?.rightIcon?.iconName,
                                  },
                                ]}
                              />
                              <img
                                src={iconconfiguration}
                                alt=""
                                width="5%"
                                onClick={() => onOpenModal("rightIcon")}
                              />
                            </span>
                          </div>
                          {/* <img
                            src={iconconfiguration}
                            alt=""
                            width="5%"
                            onClick={() => onOpenModal("rightIcon")}
                          /> */}
                        </div>
                        <div className="space-1">
                          <div className="text-small-slt">
                            <p>Selected Icon.. </p>
                            <ToolTipBox
                              iconType="green"
                              type="Selected Icon Name "
                              handleIconClick={handleIconClick}
                              showTooltipData={showTooltipData}
                              tooltipContent={tooltipContent}
                            />{" "}
                          </div>
                          <div className="svg_space">
                            <div className="slt-type">
                              <Select
                                placeholder="select.."
                                options={optionNameIcon}
                                styles={fixedStyles}
                                value={[
                                  {
                                    value: selectedFolder?.selectIcon?.iconName,
                                    label: selectedFolder?.selectIcon?.iconName,
                                  },
                                ]}
                              />
                            </div>

                            <img
                              src={iconconfiguration}
                              alt=""
                              width="5%"
                              onClick={() => onOpenModal("selectIcon")}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    {/* Add other fields for non-date types */}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <ModalEditor
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        openModal={openModal}
        closeModalEditor={closeModalEditor}
        onDisabledButtonChange={onDisabledButtonChange}
        onDisabledChange={onDisabledChange}
        folderStructure={folderStructure}
        selectedFolder={selectedFolder}
        onLabelChange={onLabelChange}
        onIndexTabChange={onIndexTabChange}
        modalData={modalData}
        onJsonOptionChangeHandler={onJsonOptionChangeHandler}
        onTitleChangeHandler={onTitleChangeHandler}
        onErrorChange={onErrorChange}
        setJsonText={setJsonText}
        jsonText={jsonText}
        setText={setText}
        text={text}
      />
      {open && (
        <Configuration
          onCloseModal={onCloseModal}
          item={selectedFolder}
          iconClickHandler={iconClickHandler}
          open={open}
        />
      )}
    </>
  );
};

export default DateInputsandVariables;
