import { useEffect, useState } from "react";
import "./login.css";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { ToastContainer, toast } from "react-toastify";
import { axiosInstance } from "../../redux/axiosInstance";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/actions/authActions";

export const Login = () => {
  const dispatch = useDispatch();

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          clientId: clientId,
          scope: "profile email",
        })
        .then(() => {
          const authInstance = gapi.auth2.getAuthInstance();
          if (authInstance?.isSignedIn?.get()) {
            const accessToken = authInstance.currentUser
              .get()
              .getAuthResponse().access_token;
            setAccessToken(accessToken);
          } else {
          }
        });
    }
    gapi.load("client:auth2", start);
  }, []);

  const handleGoogleLogin = async (response) => {
    const data = response?.profileObj;

    try {
      const apiResponse = await axiosInstance.post(
        "/googlelogin",
        {
          googleId: data.googleId,
          name: data.name,
          email: data.email,
          imageUrl: data.imageUrl,
          familyName: data.familyName,
          givenName: data.givenName,
        },
        {
          withCredentials: true,
        }
      );

      if (apiResponse.status === 200) {
        const newAccessToken = apiResponse?.data?.result?.access_token;

        localStorage.setItem("access_token", newAccessToken);

        toast.success(apiResponse?.data?.message, {
          position: "top-center",
        });

        navigate("/dashboard");
      } else {
        console.error("Google login failed:", apiResponse.data.message);
        navigate("/login");
      }
    } catch (error) {
      console.error("Error making API request:", error);
      toast.error(error?.response?.data?.message, {
        position: "top-center",
      });
      navigate("/login");
    }
  };

  const onSuccess = (res) => {
    handleGoogleLogin(res);
  };

  const onFailure = (res) => {};

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <ToastContainer />
      <div className="main_con">
        <div className="form_container">
          <div className="top_h1">
            <h2>Sign In to Your Account </h2>
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
              rememberMe: false,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "please enter a valid email address";
              }

              if (!values.password) {
                errors.password = "Password is required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (values.rememberMe) {
                localStorage.setItem("rememberedEmail", values.email);
                localStorage.setItem("rememberedPassword", values.password);
              } else {
                localStorage.removeItem("rememberedEmail", values.email);
                localStorage.removeItem("rememberedPassword", values.password);
              }

              const data = {
                email: values.email,
                password: values.password,
              };

              dispatch(loginUser(data, navigate));

              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="input_field">
                  <label className="label_em">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <div className="erros_msg">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>

                <div className="input_field">
                  <label className="label_pass">Password</label>

                  <input
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <div className="erros_msg">
                    {errors.password && touched.password && errors.password}
                  </div>
                </div>

                <div className="formBtn">
                  <button type="submit" disabled={isSubmitting}>
                    Login
                  </button>
                </div>
                <div className="input_check">
                  <label className="label_remember">Remember Me </label>
                  <input
                    className="check_box"
                    type="checkbox"
                    name="rememberMe"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.rememberMe}
                  />
                </div>

                <div className="div_gle">
                  <GoogleLogin
                    clientId={clientId}
                    buttonText="Login With Google"
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    // isSignedIn={true}
                    className="login-google"
                  />
                </div>
                <div className="get">
                  <div>
                    <Link className="get-link" to="/SignUp">
                      <h3 className="h3_Sign">Create Account</h3>
                    </Link>
                  </div>
                  <div>
                    <Link className="get-link" to="/reset">
                      <h3 className="h3_pass">Forgot Password?</h3>
                    </Link>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
