import React, { useEffect, useRef, useState, version } from "react";
import Select from "react-select";
import {
  deleteVersion,
  getScreenData,
  setPageLoading,
} from "../../../redux/actions/defaultActions";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "../../../assets/Icons/icon1.svg";
import icon2 from "../../../assets/Icons/icon2.svg";
import moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import DeleteAlign from "../../../assets/Iconcomponent/DeleteAlign";
import { SET_MYNEWDATA, SET_MYNEWDATANEW } from "../../../redux/types";
import Loader from "../../../common/Loader/Loader";

const VersionTab = ({
  screenVersionList,
  setVersions,
  versions,
  lastData,
  folderStructure,
  runtheSaveFuntion,
  runfuntion2,
  setRunFuntion2,
  pageLoading,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [versions2, setVersions2] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [date, setDate] = useState();
  const [draftOn, setdraftOn] = useState(true);

  const { screnOnlyData } = useSelector((state) => state.default);

  const version_id = localStorage.getItem("crntVersion_id");

  const ref = useRef(null);
  const dispatch = useDispatch();

  const onCloseModal = () => setOpenModal(false);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: " #1A237E",
      borderRadius: "5px",
      width: "100%",
      border: "none",
      color: "#fff",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  #1A237E" : "#1A237E",
      color: isFocused ? "white" : "white",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "white", // Customize the placeholder color
    }),
  };

  const myProjectId = localStorage.getItem("project_id");
  const screen_Id = localStorage.getItem("screen_Id");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  useEffect(() => {
    if (runfuntion2) {
      onCloseModal();
      setRunFuntion2(false);
    }
  }, [runfuntion2]);

  const openPopupHandler = (id) => {
    setShowPopup(id === showPopup ? null : id);
  };
  const values = localStorage.getItem("crntVersion_id");

  useEffect(() => {
    if (screenVersionList) {
      const combinedOptions = screenVersionList?.versions?.map((key) => ({
        value: key._id,
        label: key.versionNumber,
      }));

      const newdata = screenVersionList?.versions?.find(
        (data) => data._id === values
      );

      setDate(newdata?.createdAt);
      setVersions2(combinedOptions);
    }
  }, [screenVersionList, values]);

  const selectVersionChange = (data) => {
    if (draftOn) {
      const apiDataString = JSON.stringify(screnOnlyData?.draftData);
      const stateDataString = JSON.stringify(folderStructure);
      const myDifference = apiDataString === stateDataString;

      // setTimeout(() => {
      if (myDifference) {
        dispatch(getScreenData(myProjectId, screen_Id, data?.versionNumber));
        setVersions(data?._id);
        localStorage.setItem("crntVersion_id", data?._id);
        setdraftOn(false);
      } else {
        setOpenModal(true);
      }
      // }, 500);
    } else {
      const apiDataString = JSON.stringify(lastData?.screendata);
      const stateDataString = JSON.stringify(folderStructure);
      const myDifference = apiDataString === stateDataString;
      // setTimeout(() => {
      if (myDifference) {
        dispatch(getScreenData(myProjectId, screen_Id, data?.versionNumber));
        setVersions(data?._id);
        localStorage.setItem("crntVersion_id", data?._id);
        setdraftOn(false);
      } else {
        setOpenModal(true);
      }
      // }, 500);
    }
  };

  const selectVersionChange2 = (data) => {
    if (draftOn) {
      const apiDataString = JSON.stringify(screnOnlyData?.draftData);
      const stateDataString = JSON.stringify(folderStructure);
      const myDifference = apiDataString === stateDataString;

      if (myDifference) {
        dispatch(getScreenData(myProjectId, screen_Id, data?.label));
        setVersions(data?.value);
        localStorage.setItem("crntVersion_id", data?.value);
        setdraftOn(false);
      } else {
        setOpenModal(true);
      }
    } else {
      const apiDataString = JSON.stringify(lastData?.screendata);
      const stateDataString = JSON.stringify(folderStructure);
      const myDifference = apiDataString === stateDataString;

      if (myDifference) {
        dispatch(getScreenData(myProjectId, screen_Id, data?.label));
        setVersions(data?.value);
        localStorage.setItem("crntVersion_id", data?.value);
        setdraftOn(false);
      } else {
        setOpenModal(true);
      }
    }
  };

  const deleteVersionHandler = (data) => {
    dispatch(deleteVersion(myProjectId, screen_Id, data?._id));
  };

  const discardClickHandler = (data) => {
    if (data === "discard") {
      if (draftOn) {
        dispatch({
          type: SET_MYNEWDATA,
          payload: screnOnlyData?.draftData,
        });
      } else {
        dispatch({
          type: SET_MYNEWDATA,
          payload: screnOnlyData?.screendata,
        });
      }

      setTimeout(() => {
        onCloseModal();
      }, 1000);
    } else if (data === "save") {
      runtheSaveFuntion();
      setdraftOn(true);
    }
  };

  const selectNew = () => {
    const apiDataString = JSON.stringify(screnOnlyData?.screendata);
    const stateDataString = JSON.stringify(folderStructure);
    const myDifference = apiDataString === stateDataString;
    if (myDifference) {
      dispatch({
        type: SET_MYNEWDATA,
        payload: screnOnlyData?.draftData,
      });
      dispatch({
        type: SET_MYNEWDATANEW,
        payload: screnOnlyData,
      });
      dispatch(setPageLoading(true));
      setdraftOn(true);
      setTimeout(() => {
        dispatch(setPageLoading(false));
      }, 700);
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      <div>
        <div className="versionWrapper">
          <h4 className="versionProject">{screenVersionList?.screenName}</h4>
          <div className="selectDiv">
            <Select
              options={versions2}
              name="colors"
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Version"
              styles={colourStyles}
              onChange={selectVersionChange2}
            />
          </div>
          <div className="versionDetail">
            <p className="pNAme">Bugun</p>
            <div className="versionsMainDiv">
              {pageLoading ? (
                <Loader />
              ) : (
                <>
                  <div className={draftOn ? "versions" : "versionTime"}>
                    <div onClick={() => selectNew()}>
                      <p className="p1">0</p>
                      <p className="p2">
                        {moment(date).format("DD MMM YYYY ( HH:mm )")}
                      </p>
                      {/* <p className="p3">version 1.1 </p> */}
                      <p className="p4">
                        <img src={icon1} alt="" />
                        <span style={{ marginLeft: "5px", marginTop: "2px" }}>
                          Screen draft
                        </span>
                      </p>
                    </div>
                  </div>
                  <div>
                    {screenVersionList?.versions
                      ?.slice()
                      .reverse()
                      .map((data, key) => (
                        <div
                          className={
                            !draftOn && data?._id === versions
                              ? "versions"
                              : "versionTime"
                          }
                          key={key}
                        >
                          <div onClick={() => selectVersionChange(data)}>
                            <p className="p1">{data?.versionNumber}</p>
                            <p className="p2">
                              {moment(data?.createdAt).format(
                                "DD MMM YYYY ( HH:mm )"
                              )}
                            </p>
                            {/* <p className="p3">version 1.1 </p> */}
                            <p className="p4">
                              <img
                                src={data?._id === versions ? icon1 : icon2}
                                alt=""
                              />
                              <span
                                style={{ marginLeft: "5px", marginTop: "2px" }}
                              >
                                Burhan Karakas
                              </span>
                            </p>
                          </div>
                          {/* <div className="dotsDiv">
                    <div
                      className="dotdiv"
                      onClick={() => openPopupHandler(data?._id)}
                    >
                      <span>
                        <svg
                          width="4"
                          height="15"
                          viewBox="0 0 4 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2" cy="2" r="2" fill="#D9D9D9" />
                          <circle cx="2" cy="7.33301" r="2" fill="#D9D9D9" />
                          <circle cx="2" cy="12.667" r="2" fill="#D9D9D9" />
                        </svg>
                      </span>
                    </div>
                    {showPopup === data?._id && (
                      <div className="dots3" ref={ref}>
                        <p>Rollback</p>
                        <p onClick={() => deleteVersionHandler(data)}>Delete</p>
                      </div>
                    )}
                  </div> */}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          modal: "versionDeletion",
        }}
      >
        <div className="versionDiscardWrapper">
          <section>
            <svg
              width="70"
              height="80"
              viewBox="0 0 70 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3067_2481)">
                <path
                  d="M67.5 12.5H54.6281L49.3156 3.63281C47.8281 1.37922 45.5312 0 42.9062 0H27.0938C24.4688 0 22.0312 1.37922 20.6875 3.63281L15.3719 12.5H2.5C1.11328 12.5 0 13.6141 0 15V17.5C0 18.8906 1.11328 20 2.5 20H5V70C5 75.5234 9.47656 80 15 80H55C60.5234 80 65 75.5234 65 70V20H67.5C68.8906 20 70 18.8906 70 17.5V15C70 13.6141 68.8906 12.5 67.5 12.5ZM26.8594 7.95C27.0156 7.67656 27.3281 7.5 27.6562 7.5H42.3438C42.6758 7.5 42.9883 7.67578 43.1445 7.94922L45.875 12.5H24.125L26.8594 7.95ZM55 72.5H15C13.6192 72.5 12.5 71.3808 12.5 70V20H57.5V70C57.5 71.375 56.375 72.5 55 72.5ZM35 65C36.3819 65 37.5 63.8819 37.5 62.5V30C37.5 28.6181 36.3819 27.5 35 27.5C33.6181 27.5 32.5 28.625 32.5 30V62.5C32.5 63.875 33.625 65 35 65ZM22.5 65C23.875 65 25 63.875 25 62.5V30C25 28.6181 23.8819 27.5 22.5 27.5C21.1181 27.5 20 28.625 20 30V62.5C20 63.875 21.125 65 22.5 65ZM47.5 65C48.8819 65 50 63.8819 50 62.5V30C50 28.6181 48.8819 27.5 47.5 27.5C46.1181 27.5 45 28.625 45 30V62.5C45 63.875 46.125 65 47.5 65Z"
                  fill="#F32028"
                  fill-opacity="0.74"
                />
              </g>
              <defs>
                <clipPath id="clip0_3067_2481">
                  <rect width="70" height="80" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </section>
          <div className="textUpdate">
            <h4>Save your progress first</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
            </p>
          </div>
          <div className="btnSection">
            <button
              className="btnUpdate"
              onClick={() => discardClickHandler("discard")}
            >
              Discard
            </button>
            <button
              className="btnDelete"
              onClick={() => discardClickHandler("save")}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VersionTab;
