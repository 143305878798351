import { toast } from "react-toastify";
import { axiosInstance } from "../axiosInstance";
import {
  SET_DELETE_MY_PROJECT,
  SET_DUPLICATE_PROJECT,
  SET_MY_NEW_PROJECT,
  SET_MY_PROJECTS,
  SET_MY_PROJECT_SCREEN_LIST,
  SET_PAGE_LOADING,
  SET_SHARE_PROJECT,
} from "../types";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  },
};
export const setPageLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_PAGE_LOADING,
    payload: data,
  });
};

// export const getMyProjects = () => (dispatch) => {
//   dispatch(setPageLoading(true));

//   axiosInstance.get("projects", config).then((res) => {
//     if (res.status) {
//       dispatch({
//         type: SET_MY_PROJECTS,
//         payload: res.data,
//       });
//       dispatch(setPageLoading(false));
//     } else {
//       toast.error(res.data.message);
//     }
//   });
// };

export const getMyProjects = () => (dispatch) => {
  dispatch(setPageLoading(true));

  axiosInstance
    .get("projects", config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_MY_PROJECTS,
          payload: res.data,
        });
        dispatch(setPageLoading(false));
      } else {
        toast.error("Server Error: Unable to fetch projects");
        dispatch(setPageLoading(false));
      }
    })
    .catch((error) => {
      toast.error("Error occurred while fetching projects");
      dispatch(setPageLoading(false));
    });
};

export const addMyNewProject = (data, onCloseModal) => (dispatch) => {
  dispatch(setPageLoading(true));
  axiosInstance
    .post("addproject", data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_MY_NEW_PROJECT,
          payload: res.data,
        });
        onCloseModal();

        dispatch(setPageLoading(false));
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(setPageLoading(false));
    });
};

export const deleteMyProjectData = (data, onCloseModal) => (dispatch) => {
  axiosInstance
    .delete(`projects/${data}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DELETE_MY_PROJECT,
          payload: res.data,
        });
        onCloseModal();
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error("Error occurred while delete project");
      dispatch(setPageLoading(false));
    });
};

export const duplicateMyProject = (id) => (dispatch) => {
  dispatch(setPageLoading(true));
  axiosInstance
    .post(`duplicateproject/${id}`, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_DUPLICATE_PROJECT,
          payload: res.data,
        });

        dispatch(setPageLoading(false));
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(setPageLoading(false));
    });
};

export const shareMyProject = (data, closeShareModal) => (dispatch) => {
  dispatch(setPageLoading(true));
  axiosInstance
    .post(`share`, data, config)
    .then((res) => {
      if (res.status) {
        toast.success(res.data.message);
        dispatch({
          type: SET_SHARE_PROJECT,
          payload: res.data,
        });

        dispatch(setPageLoading(false));
        closeShareModal();
      } else {
        toast.error(res.data.message);
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(setPageLoading(false));
    });
};

export const getMyAllScreen = (id) => (dispatch) => {
  axiosInstance
    .get(`getScreenData/${id}`, config)
    .then((res) => {
      if (res.status) {
        dispatch({
          type: SET_MY_PROJECT_SCREEN_LIST,
          payload: res.data,
        });
      } else {
        toast.error("Server Error: Unable to fetch projects");
      }
    })
    .catch((error) => {
      toast.error("Error occurred while fetching projects");
    });
};
