import React, { useState } from "react";
import Modal from "react-responsive-modal";
import moment from "moment";
import ResponsiveDateTimePickers from "../rightTabCommon/DateTimePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DatePicker = ({
  onClicks,
  item,
  opens,
  closeDataModal,
  keys,
  datetimeOnChange,
  selectedFolder,
  isDraftPreview,
  readOnly,
}) => {
  // let dateData = new Date(item?.dateData);
  // let formattedDate = !isNaN(dateData) ? dateData.toLocaleDateString() : "";
  // let formattedTime = !isNaN(dateData) ? dateData.toLocaleTimeString() : "";

  let dateData = new Date(item?.dateData);
  let formattedDate = "";
  let formattedTime = "";

  // Check if dateData is valid
  if (!isNaN(dateData)) {
    try {
      // Check if the format is valid using Moment.js
      if (moment(dateData).isValid()) {
        // Format the date using the provided format or fallback to default
        formattedDate = moment(dateData).format(item.format || "MM/DD/YYYY");
        formattedTime = moment(dateData).format(item.format || "hh:mm:ss A");
      } else {
        // Fallback to default if invalid
        formattedDate = dateData.toLocaleDateString();
        formattedTime = dateData.toLocaleTimeString();
      }
    } catch (error) {
      // If the format is invalid, use the default format
      formattedDate = dateData.toLocaleDateString();
      formattedTime = dateData.toLocaleTimeString();
    }
  } else {
    formattedDate = "";
    formattedTime = "";
  }

  const marginStyle = item.margin
    ? {
        margin: item.margin[0].all + item.margin[0].unit,
        marginLeft: item.margin[1].left + item.margin[1].unit,
        marginTop: item.margin[2].top + item.margin[2].unit,
        marginRight: item.margin[3].right + item.margin[3].unit,
        marginBottom: item.margin[4].bottom + item.margin[4].unit,
      }
    : {};
  const paddingStyle = item.padding
    ? {
        padding: item.padding[0].all + item.padding[0].unit,
        paddingLeft: item.padding[1].left + item.padding[1].unit,
        paddingTop: item.padding[2].top + item.padding[2].unit,
        paddingRight: item.padding[3].right + item.padding[3].unit,
        paddingBottom: item.padding[4].bottom + item.padding[4].unit,
      }
    : {};
  const radiusStyle = item.borderRadius
    ? {
        borderTopLeftRadius:
          item.borderRadius[1].topLeft + item.borderRadius[1].unit,
        borderTopRightRadius:
          item.borderRadius[2].topRight + item.borderRadius[2].unit,
        borderBottomLeftRadius:
          item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
        borderBottomRightRadius:
          item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
      }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
        borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1].unit,
        borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
        borderRightWidth: item.borderWidth[3].right + item.borderWidth[3].unit,
        borderBottomWidth:
          item.borderWidth[4].bottom + item.borderWidth[4].unit,
      }
    : {};

  const width = parseInt(item.size[0].width);
  const height = parseInt(item.size[1].height);
  const min_height = parseInt(item.size[3]?.min_height);
  const min_width = parseInt(item.size[2]?.min_width);
  const max_height = parseInt(item.size[5]?.max_height);
  const max_width = parseInt(item.size[4]?.max_width);
  const widthUnit = item.size[0]?.unit;
  const heightUnit = item.size[1]?.unit;
  const min_heightunit = item.size[3]?.unit;
  const min_widthunit = item.size[2]?.unit;
  const max_heightunit = item.size[5]?.unit;
  const max_widthunit = item.size[4]?.unit;

  return (
    <>
      <div
        className="datepicker_wrapper"
        onClick={(e) => onClicks(e, item)}
        key={keys}
        style={{
          backgroundColor: item.id
            ? item?.buttondisable === "true"
              ? "#9295a899"
              : "#1a237e38"
            : null,
          zIndex: "20",
          width: `${width}${widthUnit}`,
          height: `${height}${heightUnit}`,
          minHeight: `${min_height}${min_heightunit}`,
          minWidth: `${min_width}${min_widthunit}`,
          maxHeight: `${max_height}${max_heightunit}`,
          maxWidth: `${max_width}${max_widthunit}`,
          cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
          borderColor: item.id
            ? opens === true
              ? item?.activeBorderColor
              : item.borderColor
            : "#000000",
          borderStyle: item.id ? item.borderStyle === "solid" : "solid",
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          borderWidth:
            item?.borderWidth?.[0].all > 0
              ? item.borderWidth[0].all + item.borderWidth[0]?.unit
              : borderWidthStyle,
          borderRadius:
            item?.borderRadius?.[0].all > 0
              ? item.borderRadius[0].all + item.borderRadius[0]?.unit
              : radiusStyle,
        }}
        disabled={item?.buttondisable}
      >
        <div className="datepicker_main">
          {item?.labelShowHide === "show" && (
            <label
              htmlFor=""
              className="datepicker_label"
              style={{
                color: item?.labelColor,
                fontSize: `${item?.labelSize}px`,
              }}
            >
              {item.label ? item.label : "date"}
            </label>
          )}

          <div className="datepicker_datetime">
            {item?.leftIcon && (
              <span style={{ marginLeft: "8px" }}>
                <FontAwesomeIcon
                  icon={item?.leftIcon}
                  style={{ fontSize: 18 }}
                />
              </span>
            )}
            <span
              className="datepicker_time "
              style={{
                zIndex: "10",

                cursor: isDraftPreview ? "pointer" : "default",
                color: item.id ? item.color : null,
                fontSize: item.id ? `${item.fontSize}px` : "12px",
                lineHeight: item.id ? `${item.lineHeight}px` : "12px",
                letterSpacing: item.id ? `${item.letterSpacing}px` : null,
                fontFamily: item.id ? item.fontFamily : null,
                textAlign: item.id ? item.textAlign : null,
                fontWeight:
                  item.id && item.fontWeight.length > 0
                    ? item.fontWeight.find((obj) => obj.selectedFont)?.Weight
                    : null,
                textDecoration: item.id ? item.textdecoration : null,
                textTransform: item.id ? item.textTransform : null,
              }}
              disabled={item?.buttondisable}
            >
              {item?.mode && item?.mode === "date" && formattedDate}
              {item?.mode && item?.mode === "time" && formattedTime}
              {item?.mode &&
                item?.mode === "dateTime" &&
                `${formattedDate},${formattedTime}`}
            </span>
            {item?.rightIcon && (
              <span style={{ marginLeft: "8px" }}>
                <FontAwesomeIcon
                  icon={item?.rightIcon}
                  style={{ fontSize: 18 }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <span style={{ color: "#141a2f" }}>{item?.assitive}</span>

      <>
        <ResponsiveDateTimePickers
          data={datetimeOnChange}
          open={opens}
          onClose={closeDataModal}
          item={item}
          selectedFolder={selectedFolder}
          disabled={item?.buttondisable === "true"}
        />
      </>
    </>
  );
};

export default DatePicker;
