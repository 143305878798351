import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { easing } from "@mui/material";
import React from "react";
import { Button } from "react-bootstrap";
import ButtonLoader from "../../../../common/Loader/ButtonLoader";

const ButtonComponent = ({ uploadButtonhandler, item, isDraftPreview }) => {
  const marginStyle = item.margin
    ? {
        margin: item.margin[0].all + item.margin[0]?.unit,
        marginLeft: item.margin[1].left + item.margin[1]?.unit,
        marginTop: item.margin[2].top + item.margin[2]?.unit,
        marginRight: item.margin[3].right + item.margin[3]?.unit,
        marginBottom: item.margin[4].bottom + item.margin[4]?.unit,
      }
    : {};
  const paddingStyle = item.padding
    ? {
        padding: item.padding[0].all + item.padding[0]?.unit,
        paddingLeft: item.padding[1].left + item.padding[1]?.unit,
        paddingTop: item.padding[2].top + item.padding[2]?.unit,
        paddingRight: item.padding[3].right + item.padding[3]?.unit,
        paddingBottom: item.padding[4].bottom + item.padding[4]?.unit,
      }
    : {};

  const borderWidthStyle = item.borderWidth
    ? {
        borderWidth: item.borderWidth[0].all + item.borderWidth[0]?.unit,
        borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1]?.unit,
        borderTopWidth: item.borderWidth[2].top + item.borderWidth[2]?.unit,
        borderRightWidth: item.borderWidth[3].right + item.borderWidth[3]?.unit,
        borderBottomWidth:
          item.borderWidth[4].bottom + item.borderWidth[4]?.unit,
      }
    : {};

  const width = parseInt(item.size[0].width);
  const height = parseInt(item.size[1].height);
  const min_height = parseInt(item.size[3]?.min_height);
  const min_width = parseInt(item.size[2]?.min_width);
  const max_height = parseInt(item.size[5]?.max_height);
  const max_width = parseInt(item.size[4]?.max_width);
  const widthUnit = item.size[0]?.unit;
  const heightUnit = item.size[1]?.unit;
  const min_heightunit = item.size[3]?.unit;
  const min_widthunit = item.size[2]?.unit;
  const max_heightunit = item.size[5]?.unit;
  const max_widthunit = item.size[4]?.unit;

  const RadiusStyle = item.borderRadius
    ? {
        borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
        borderRadiusLeft:
          item.borderRadius[1].topLeft + item.borderRadius[1]?.unit,
        borderRadiusTop:
          item.borderRadius[2].topRight + item.borderRadius[2]?.unit,
        borderRadiusRight:
          item.borderRadius[3].bottomLeft + item.borderRadius[3]?.unit,
        borderRadiusBottom:
          item.borderRadius[4].bottomRight + item.borderRadius[4]?.unit,
      }
    : {};

  // console.log(item.fontWeight.find((obj) => obj.selectedFont)?.Weight);

  return (
    <>
      <div
        className="main_button"
        onClick={(e) => uploadButtonhandler(e, item)}
      >
        {/* backgroundColor: item.id
            ? item?.buttondisable
              ? "#9295a899"
              : item?.background
            : null, */}

        <Button
          style={{
            backgroundColor: item.id
              ? item?.buttondisable === "true"
                ? "#9295a899"
                : item?.background
              : null,
            resize: "none",
            zIndex: "10",
            cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
            width: `${width}${widthUnit}`,
            height: `${height}${heightUnit}`,
            minHeight: `${min_height}${min_heightunit}`,
            minWidth: `${min_width}${min_widthunit}`,
            maxHeight: `${max_height}${max_heightunit}`,
            maxWidth: `${max_width}${max_widthunit}`,
            color: item.id ? item.color : null,
            fontSize: item.id ? `${item.fontSize}px` : "12px",
            lineHeight: item.id ? `${item.lineHeight}px` : "12px",
            letterSpacing: item.id ? `${item.letterSpacing}px` : null,
            fontFamily: item.id ? item.fontFamily : null,
            textAlign: item.id ? item.textAlign : null,
            // fontWeight: item.id ? item.fontWeight : null,
            fontWeight:
              item.id && item.fontWeight.length > 0
                ? item.fontWeight.find((obj) => obj.selectedFont)?.Weight
                : null,
            textDecoration: item.id ? item.textdecoration : null,
            borderColor: item.id ? item.borderColor : null,
            borderStyle: item.id ? item.borderStyle : null,
            textTransform: item.id ? item.textTransform : null,
            ...marginStyle,
            ...paddingStyle,
            ...borderWidthStyle,
            ...RadiusStyle,
            cursor: "pointer",
          }}
          className={`btn ${item.buttonLoading === "true" ? "disabled" : ""}`}
          disabled={item.buttondisable}
        >
          {item?.buttonIcon && (
            <span>
              {" "}
              <FontAwesomeIcon
                icon={item?.buttonIcon}
                style={{ fontSize: 20 }}
              />
            </span>
          )}{" "}
          {item.buttonLoading === "true" ? <ButtonLoader /> : ""}
          {item.button_text ? item.button_text : ""}
        </Button>
      </div>
    </>
  );
};

export default ButtonComponent;
