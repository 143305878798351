import React from "react";

const ButtonLoader = ({ item }) => {
  return (
    <div>
      {" "}
      <div class="spinner-border2" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  );
};

export default ButtonLoader;
