import React, { useState, useEffect } from "react";
import DocsNavbar from "./DocsNavbar";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import MainDocsPage from "./MainDocsPage";

const Document = () => {
  const { docId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedData, setSelectedData] = useState({});
  const [tabs, setTabs] = useState();
  const [documentLoading, setDocumentLoading] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const listItems = [
    {
      name: "GETTING STARTED",
    },
    {
      url: "welcome-to-figma-clone",
      name: "Welcome To Figma Clone",
      headings: [
        { url: "before-you-build", name: "Before you Build" },
        { url: "start-building!", name: "Start Building!" },
        {
          url: "add-advanced-functionality",
          name: "Add Advanced Functionality",
        },
      ],
    },
    {
      url: "dashboard",
      name: "Dashboard",
      headings: [
        { url: "workspaces", name: "Workspaces" },
        { url: "starter-apps", name: "Starter Apps" },
        { url: "example-screens", name: "Example Screens" },
        { url: "expert-services", name: "Expert Services" },
        { url: "expert-projects", name: "Expert Projects" },
        { url: "support-and-resources", name: "Support and Resources" },
        { url: "account-settings", name: "Account Settings" },
      ],
    },
    {
      url: "workspaces",
      name: "Workspaces",
      headings: [
        { url: "setting-up-a-workspace", name: "Setting Up a Workspace" },
        { url: "workspace-settings", name: "Workspace Settings" },
        { url: "settings", name: "Settings" },
        { url: "collaborators", name: "Collaborators" },
        { url: "inviting-collaborators", name: "Inviting Collaborators" },
      ],
    },
    {
      url: "community-resources",
      name: "Community Resources",
      headings: [
        { url: "javaScript", name: "JavaScript" },
        { url: "react-and-react-native", name: "React and React Native" },
        { url: "data-and-apis", name: "Data and APIs" },
        { url: "payments", name: "Payments" },
      ],
    },
    {
      url: "faqs",
      name: "FAQs",
      headings: [
        { url: "why-react-native?", name: "Why React Native?" },
        { url: "why-expo?", name: "Why Expo?" },
        { url: "expo-cli", name: "Expo CLI" },
        {
          url: "why-doesn't-my-app-in-the-browser-(Web-Preview)-match-my-app-in-Live-Preview?",
          name: "Why doesn't my app in the browser (Web Preview) match my app in Live Preview?",
        },
        {
          url: "can-I-put-my-app-on-the-app-store/Google-play-store?",
          name: "Can I put my app on the App Store/Google Play Store?",
        },
        {
          url: "how-do-i-request-a-feature?",
          name: "How do I request a feature?",
        },
        {
          url: "how-do-i-report-a-bug-or-issue?",
          name: "How do I report a bug or issue?",
        },
        {
          url: "how-much-does-draftbit-cost?",
          name: "How much does Draftbit cost?",
        },
        {
          url: "how-do-i-cancel-my-account?",
          name: "How do I cancel my account?",
        },
        {
          url: "can-i-share-my-draftbit-app-with-others?",
          name: "Can I share my Draftbit app with others?",
        },
        {
          url: "i-didn't-mean-to-delete-my-app-can-i-get-it-back?",
          name: "I didn't mean to delete my app. Can I get it back?",
        },
        {
          url: "additional-resources",
          name: "Additional Resources",
        },
      ],
    },
    {
      name: "COMPONENTS",
    },
    {
      url: "intro-to-components",
      name: "Intro To Components",
      headings: [
        { url: "component-tree", name: "Component Tree" },
        { url: "adding-components", name: "Adding Components" },
        { url: "moving-components", name: "Moving Components" },
        { url: "nesting-components", name: "Nesting Components" },
        { url: "duplicating-components", name: "Duplicating Components" },
        { url: "copy-pasting-components", name: "Copy/Pasting Components" },
        { url: "adding-a-parent-component", name: "Adding a Parent Component" },
        { url: "hiding-components", name: "Hiding Components" },
        { url: "deleting-components", name: "Deleting Components" },
        { url: "restoring-components", name: "Restoring Components" },
        { url: "converting-to-blocks", name: "Converting to Blocks" },
        { url: "conditional-display", name: "Conditional Display" },
      ],
    },
    {
      url: "basic",
      name: "Basic",
      children: [
        {
          url: "screen",
          name: "Screen",
          headings: [
            { url: "icon-sets", name: "Icon Sets", depth: 2 },
            { url: "style-properties", name: "Style Properties", depth: 2 },
            {
              url: "component-specific-styles",
              name: "Component Specific Styles",
              depth: 2,
            },
            {
              url: "configuration-properties",
              name: "Configuration Properties",
              depth: 2,
            },
            { url: "data-configuration", name: "Data Configuration", depth: 2 },
            {
              url: "conditional-display",
              name: "Conditional Display",
              depth: 2,
            },
          ],
        },
        {
          url: "icon",
          name: "Icon",
          headings: [
            { url: "icon-sets", name: "Icon Sets", depth: 2 },
            { url: "style-properties", name: "Style Properties", depth: 2 },
            {
              url: "component-specific-styles",
              name: "Component Specific Styles",
              depth: 2,
            },
            {
              url: "configuration-properties",
              name: "Configuration Properties",
              depth: 2,
            },
            { url: "data-configuration", name: "Data Configuration", depth: 2 },
            {
              url: "conditional-display",
              name: "Conditional Display",
              depth: 2,
            },
          ],
        },
        {
          url: "text",
          name: "Text",
          headings: [
            { url: "text-formatting", name: "Text Formatting", depth: 2 },
            { url: "text-style", name: "Text Style", depth: 2 },
          ],
        },
        {
          url: "view",
          name: "View",
          headings: [
            { url: "view-layout", name: "View Layout", depth: 2 },
            { url: "view-properties", name: "View Properties", depth: 2 },
          ],
        },
      ],
    },
    {
      url: "buttons",
      name: "Buttons",
      children: [
        { url: "button", name: "Button", depth: 2 },
        { url: "icon-button", name: "Icon Button", depth: 2 },
        // {
        //   url: "link",
        //   name: "Link",
        //   depth: 2,
        // },
        // { url: "touchable", name: "Touchable", depth: 2 },
        { url: "pressable", name: "Pressable", depth: 2 },
      ],
    },
    // {
    //   url: "views",
    //   name: "Views",
    //   children: [
    //     { url: "blur-view", name: "Blur View", depth: 2 },
    //     {
    //       url: "keyboard-avoiding-view",
    //       name: "Keyboard Avoiding View",
    //       depth: 2,
    //     },
    //     {
    //       url: "keyboard-aware-scroll-view",
    //       name: "Keyboard Aware Scroll View",
    //       depth: 2,
    //     },
    //     { url: "scroll-view", name: "Scroll View", depth: 2 },
    //     { url: "shadow", name: "Shadow", depth: 2 },
    //   ],
    // },
    {
      url: "flex-layout",
      name: "Flex Layout",
      children: [
        { url: "aspect-ratio", name: "Aspect Ratio", depth: 2 },
        { url: "center", name: "Center", depth: 2 },
        {
          url: "circle",
          name: "Circle",
          depth: 2,
        },
        { url: "square", name: "Square", depth: 2 },
        // { url: "linear-gradient", name: "Linear Gradient", depth: 2 },
        // { url: "h-stack", name: "H Stack", depth: 2 },
        // { url: "v-stack", name: "V Stack", depth: 2 },
        // { url: "z-stack", name: "Z Stack", depth: 2 },
        // { url: "divider", name: "Divider", depth: 2 },
        // { url: "spacer", name: "Spacer", depth: 2 },
      ],
    },
    // {
    //   url: "containers",
    //   name: "Containers",
    //   children: [
    //     { url: "accordion", name: "Accordion", depth: 2 },
    //     { url: "surface", name: "Surface", depth: 2 },
    //   ],
    // },
    // {
    //   url: "media",
    //   name: "Media",
    //   children: [
    //     { url: "image", name: "Image", depth: 2 },
    //     { url: "image-background", name: "Image Background", depth: 2 },
    //     { url: "svg", name: "SVG", depth: 2 },
    //     { url: "audio-player", name: "Audio Player", depth: 2 },
    //     { url: "video-player", name: "Video Player", depth: 2 },
    //     { url: "youtube-player", name: "Youtube Player", depth: 2 },
    //   ],
    // },
    // {
    //   url: "lists-data",
    //   name: "Lists & Data",
    //   children: [
    //     { url: "fetch", name: "Fetch", depth: 2 },
    //     { url: "list", name: "List", depth: 2 },
    //     { url: "flashList", name: "FlashList", depth: 2 },
    //     { url: "masonry-list", name: "Masonry List", depth: 2 },
    //     {
    //       url: "section-list",
    //       name: "Section List",
    //       depth: 2,
    //       children: [
    //         { url: "section-header", name: "Section Header", depth: 3 },
    //       ],
    //     },
    //     // {
    //     //   url: "swipeable-list",
    //     //   name: "Swipeable List",
    //     //   depth: 2,
    //     //   children: [
    //     //     { url: "swipable-item", name: "Swipable Item", depth: 3 },
    //     //     {
    //     //       url: "swipeable-item-button",
    //     //       name: "Swipeable Item Button",
    //     //       depth: 3,
    //     //     },
    //     //   ],
    //     // },
    //     // {
    //     //   url: "table",
    //     //   name: "Table",
    //     //   depth: 2,
    //     //   children: [
    //     //     { url: "table-row", name: "Table Row", depth: 3 },
    //     //     { url: "table-cell", name: "Table Cell", depth: 3 },
    //     //   ],
    //     // },
    //   ],
    // },
    // {
    //   url: "form-inputs",
    //   name: "Form Inputs",
    //   children: [
    //     { url: "text-input", name: "Text Input", depth: 2 },
    //     { url: "text-area", name: "Text Area", depth: 2 },
    //     { url: "number-input", name: "Number Input", depth: 2 },
    //     { url: "styled-text-field", name: "Styled Text Field", depth: 2 },
    //     {
    //       url: "picker",
    //       name: "Picker",
    //       depth: 2,
    //       children: [{ url: "picker-item", name: "Picker Item", depth: 3 }],
    //     },
    //     { url: "date-picker", name: "Date Picker", depth: 2 },
    //     { url: "multi-select-picker", name: "Multi-Select Picker", depth: 2 },
    //     { url: "pin-input", name: "PIN Input", depth: 2 },
    //   ],
    // },
    // {
    //   url: "form-controls",
    //   name: "Form Controls",
    //   children: [
    //     { url: "switch", name: "Switch", depth: 2 },
    //     { url: "switch-row", name: "Switch Row", depth: 2 },
    //     { url: "checkbox", name: "Checkbox", depth: 2 },
    //     { url: "checkbox-row", name: "Checkbox Row", depth: 2 },
    //     { url: "radio-button", name: "Radio Button", depth: 2 },
    //     { url: "radio-button-row", name: "Radio Button Row", depth: 2 },
    //     { url: "radio-button-group", name: "Radio Button Group", depth: 2 },
    //     { url: "slider", name: "Slider", depth: 2 },
    //     { url: "star-rating", name: "Star Rating", depth: 2 },
    //     { url: "stepper", name: "Stepper", depth: 2 },
    //   ],
    // },
    // {
    //   url: "maps",
    //   name: "Maps",
    //   children: [
    //     { url: "map-view", name: "Map View", depth: 2 },
    //     { url: "map-marker", name: "Map Marker", depth: 2 },
    //     { url: "map-callout", name: "Map Callout", depth: 2 },
    //     {
    //       url: "map-marker-cluster",
    //       name: "Map Marker Cluster",
    //       depth: 2,
    //       children: [
    //         {
    //           url: "map-marker-cluster-view ",
    //           name: "Map Marker Cluster View",
    //           depth: 3,
    //         },
    //       ],
    //     },
    //     {
    //       url: "map-circle",
    //       name: "Map Circle",
    //       depth: 2,
    //     },
    //   ],
    // },
    // {
    //   url: "swipers",
    //   name: "Swipers",
    //   children: [
    //     {
    //       url: "swiper",
    //       name: "Swiper",
    //       depth: 2,
    //       children: [{ url: "swiper-item", name: "Swiper Item", depth: 3 }],
    //     },
    //     {
    //       url: "deck-swiper",
    //       name: "Deck Swiper",
    //       depth: 2,
    //       children: [
    //         { url: "deck-swiper-card", name: "Deck Swiper Card", depth: 3 },
    //       ],
    //     },
    //     {
    //       url: "tab-view",
    //       name: "Tab View",
    //       depth: 2,
    //       children: [{ url: "tab-view-item", name: "Tab View Item", depth: 3 }],
    //     },
    //   ],
    // },
    // {
    //   url: "text-elements",
    //   name: "Text Elements",
    //   children: [
    //     { url: "headingss", name: "Headings", depth: 2 },
    //     { url: "markdown", name: "Markdown", depth: 2 },
    //   ],
    // },
    // {
    //   url: "overlays",
    //   name: "Overlays",
    //   children: [
    //     { url: "modal", name: "Modal", depth: 2 },
    //     { url: "bottom-sheet", name: "Bottom Sheet", depth: 2 },
    //     {
    //       url: "action-sheet",
    //       name: "Action Sheet",
    //       depth: 2,
    //       children: [
    //         { url: "action-sheet-item", name: "Action Sheet Item", depth: 3 },
    //         {
    //           url: "action-sheet-cancel",
    //           name: "Action Sheet Cancel",
    //           depth: 3,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   url: "utilities",
    //   name: "Utilities",
    //   children: [
    //     { url: "activity-indicator", name: "Activity Indicator", depth: 2 },
    //     { url: "linear-progress", name: "Linear Progress", depth: 2 },
    //     { url: "circular-progress", name: "Circular Progress", depth: 2 },
    //     { url: "web-view", name: "Web View", depth: 2 },
    //     { url: "html-view", name: "HTML View", depth: 2 },
    //     { url: "custom-code", name: "Custom Code", depth: 2 },
    //   ],
    // },
    {
      url: "blocks",
      name: "Block",
      depth: 1,
      headings: [
        { url: "blocks-view", name: "Blocks View" },
        { url: "block-list", name: "Block List" },
        { url: "block-components", name: "Block Components" },
        { url: "block-properties", name: "Block Properties" },
        { url: "block-workflows", name: "Block Workflows" },
        { url: "block-variables", name: "Block Variables" },
        { url: "block-functions", name: "Block Functions" },
        { url: "background-color-switcher", name: "Background Color Switcher" },
        { url: "creating-blocks", name: "Creating Blocks" },
        { url: "from-screen-components", name: "From Screen Components" },
        { url: "duplicating-a-block", name: "Duplicating a Block" },
        { url: "using-blocks", name: "Using Blocks" },
        { url: "in-screens", name: "In Screens" },
      ],
    },
    { name: "STYLING" },
    {
      url: "intro-to-styling",
      name: "Intro To Styling",
      headings: [
        { url: "intro-to-styling", name: "Intro to Styling" },
        { url: "sections", name: "Sections" },
      ],
    },
    {
      url: "stylesheets",
      name: "Stylesheets",
      headings: [
        { url: "stylesheets", name: "Stylesheets" },
        { url: "how-it-works", name: "How It Works:" },
        { url: "stylesheet-management", name: "Stylesheet Management" },
      ],
    },
    {
      url: "dynamic-styling",
      name: "Dynamic Styling",
      headings: [{ url: "dynamic-styling", name: "Dynamic Styling" }],
    },
    {
      url: "conditional-display",
      name: "Conditional Display",
      headings: [{ url: "conditional-display", name: "Conditional Display" }],
    },
    {
      url: "flex-layout-style",
      name: "Layout",
      headings: [
        { url: "flex-layout", name: "Flex Layout" },
        { url: "flex-items", name: "Flex Items" },
        { url: "flex-direction", name: "Flex Direction" },
        { url: "align-items", name: "Align Items" },
        { url: "justify-content", name: "Justify Content" },
        { url: "gap", name: "Gap" },
        { url: "selected-item", name: "Selected Item" },
        { url: "align-item", name: "Align Item" },
        { url: "flex-item", name: "Flex Item" },
        { url: "wrap-content", name: "Wrap Content" },
        { url: "align-content", name: "Align Content" },
        { url: "additional-resources", name: "Additional Resources" },
      ],
    },
    {
      url: "typography",
      name: "Typography",
      headings: [
        { url: "typography", name: "Typography" },
        { url: "style", name: "Style" },
        { url: "font", name: "Font" },
        { url: "color", name: "Color" },
        { url: "align", name: "Align" },
        { url: "transform", name: "Transform" },
        { url: "decoration", name: "Decoration" },
      ],
    },
    {
      url: "margins-padding",
      name: "Margins & Padding",
      headings: [
        { url: "margins-padding", name: "Margins & Padding" },

        { url: "additional-resources", name: "Additional Resources" },
      ],
    },
    {
      url: "borders-background-effect",
      name: "Borders, Background, Effect",
      headings: [
        { url: "borders", name: "Borders" },
        { url: "background", name: "Background" },
        { url: "effects", name: "Effects" },
      ],
    },
    {
      url: "size-position",
      name: "Size & Position",
      headings: [
        { url: "size", name: "Size" },
        { url: "position", name: "Position" },
        { url: "additional-resources", name: "Additional Resources" },
      ],
    },
    // { url: "variables", name: "Variables", depth: 1 },
    // { url: "intro-to-variables", name: "Intro To Variables", depth: 1 },
    // { url: "app-variables", name: "App Variables", depth: 1 },
    // { url: "device-variables", name: "Device Variables", depth: 1 },
    // { url: "screen-variables", name: "Screen Variables", depth: 1 },
    // { url: "system-variables", name: "System Variables", depth: 1 },
    // { url: "variables-in-code", name: "Variables In Code", depth: 1 },
    // { url: "navigation", name: "Navigation", depth: 1 },
    // { url: "intro-to-navigation", name: "Intro To Navigation", depth: 1 },
    // { url: "stack-navigator", name: "Stack Navigator", depth: 1 },
    // {
    //   url: "tab-navigator",
    //   name: "Tab Navigator",
    //   depth: 1,
    //   children: [{ url: "Tab Bar Block", name: "Tab Bar Block", depth: 2 }],
    // },
    // { url: "passing-data", name: "Passing Data", depth: 1 },
    // { url: "header-buttons", name: "Header Buttons", depth: 1 },
    // { url: "interactions", name: "Interactions", depth: 1 },
    // { url: "action-editor", name: "Action Editor", depth: 1 },
    // { url: "triggers", name: "Triggers", depth: 1 },
    // { url: "actions", name: "Actions", depth: 1 },
    // { url: "custom-code", name: "Custom Code", depth: 1 },
    // { url: "intro-to-custom-code", name: "Intro To Custom Code", depth: 1 },
    // { url: "editor", name: "Editor", depth: 1 },
    // { url: "code-assistant", name: "Code Assistant", depth: 1 },
    // { url: "functions", name: "Functions", depth: 1 },
    // { url: "files", name: "Files", depth: 1 },
    // { url: "jsx", name: "JSX", depth: 1 },
    // { url: "packages", name: "Packages", depth: 1 },
    { name: "CONNECTING TO DATA" },
    {
      url: "rest-api-services",
      name: "Rest API Services",
      depth: 1,
      headings: [
        { url: "adding-a-service", name: "Adding a Service" },
        {
          url: "importing-from-another-app",
          name: "Importing from Another App",
        },
        { url: "configuring-a-service", name: "Configuring a Service" },
        { url: "api-basics", name: "API Basics" },
        { url: "openapi-schema", name: "OpenAPI Schema" },
        { url: "headers", name: "Headers" },
        { url: "editing-a-service", name: "Editing a Service" },
        { url: "duplicating-a-service", name: "Duplicating a Service" },
        { url: "deleting-a-service", name: "Deleting a Service" },
      ],
    },
    {
      url: "rest-api-endpoints",
      name: "Rest API Endpoints",
      depth: 1,
      headings: [
        { url: "adding-an-endpoint", name: "Adding an Endpoint" },
        { url: "configuring-an-endpoint", name: "Configuring an Endpoint" },
        { url: "basics", name: "Basics" },
        { url: "object-and-role", name: "Object & Role" },
        { url: "path-and-parameters", name: "Path & Parameters" },
        { url: "request-body", name: "Request Body" },
        { url: "headers", name: "Headers" },
        { url: "variables", name: "Variables" },
        { url: "test-and-preview-response", name: "Test & Preview Response" },
        { url: "editing-an-endpoint", name: "Editing an Endpoint" },
        { url: "duplicating-an-endpoint", name: "Duplicating an Endpoint" },
        { url: "deleting-an-endpoint", name: "Deleting an Endpoint" },
        { url: "cors-issues", name: "CORS Issues" },
        { url: "additional-resources", name: "Additional Resources" },
      ],
    },
    {
      name: "WORKING WITH DATA",

      children: [
        { url: "fetching-data", name: "Fetching Data", depth: 2 },
        { url: "submitting-data", name: "Submitting Data", depth: 2 },
      ],
    },
    {
      url: "fetching-data",
      name: "Fetching Data",
      depth: 1,
      headings: [
        { url: "fetch-component", name: "Fetch Component" },
        { url: "api-request-action", name: "API Request Action" },
        { url: "data-enabled-components", name: "Data-Enabled Components" },
        {
          url: "configure-endpoint-fetch-list",
          name: "Configure an endpoint to Fetch a list of items",
        },
        {
          url: "display-list-data-fetch-component",
          name: "Display a list of data with Fetch Component",
        },
        {
          url: "configure-endpoint-fetch-single-item",
          name: "Configure an endpoint to Fetch a single item",
        },
        {
          url: "display-single-data-fetch-component",
          name: "Display single data object using Fetch Component",
        },
      ],
    },
    {
      url: "submitting-data",
      name: "Submitting Data",
      depth: 1,
      headings: [
        {
          url: "configure-endpoint-submit-new-data",
          name: "Configure the endpoint to submit new data",
        },
        {
          url: "trigger-api-request-submit-new-item",
          name: "Trigger an API Request action to submit a new item",
        },
        {
          url: "configure-endpoint-update-existing-data",
          name: "Configure the endpoint to update existing data",
        },
        {
          url: "trigger-api-request-update-existing-item",
          name: "Trigger an API Request action to update an existing item",
        },
        {
          url: "configure-endpoint-delete-existing-data",
          name: "Configure the endpoint to delete existing data",
        },
        {
          url: "trigger-api-request-submit-new-item-duplicate",
          name: "Trigger an API Request action to submit a new item",
        },
      ],
    },
    { name: "DATA INTEGRATIONS" },
    // { url: "rest-api-integrations", name: "REST API Integrations", depth: 1 },
    {
      url: "example-data",
      name: "Example Data",
      headings: [
        {
          url: "what-is-example-data-service",
          name: "What is Example Data Service?",
        },
        {
          url: "setup-steps-in-example-data-service",
          name: "Setup Steps in Example Data Service",
        },
        {
          url: "using-example-data-api-with-draftbit",
          name: "Using Example Data API with Draftbit",
        },
        { url: "on-app-screen", name: "On App Screen" },
        { url: "on-app-screen-duplicate", name: "On App Screen:" }, // Duplicate heading
        {
          url: "post-request-to-submit-new-data",
          name: "POST Request to Submit New Data",
        },
        {
          url: "to-submit-data-from-within-the-app",
          name: "To Submit Data from Within the App:",
        },
        { url: "similar-to-post-request", name: "Similar to POST Request:" },
      ],
    }, // { url: "authentications", name: "Authentications", depth: 1 },
    // {
    //   url: "intro-to-authentications",
    //   name: "Intro To Authentications",
    //   depth: 1,
    // },
    // { url: "back4app", name: "Back4App", depth: 1 },
    // { url: "backendless", name: "Backendless", depth: 1 },
    // { url: "bubble", name: "Bubble", depth: 1 },
    // { url: "supabase", name: "Supabase", depth: 1 },
    // { url: "xano", name: "Xano", depth: 1 },
    // { url: "laravel", name: "Laravel", depth: 1 },
    {
      name: "EXPORTING, PUBLISHING & SHARING",
    },
    {
      url: "intro-to-publishing",
      name: "Intro To Publishing",
      headings: [
        { url: "version-publishing-status", name: "Version Publishing Status" },
        { url: "publishing-environment", name: "Publishing Environment" },
        { url: "additional-resources", name: "Additional Resources" },
      ],
    }, // {
    //   url: "publishing-your-app-to-the-apple-app-store",
    //   name: "Publishing Your App to the Apple App Store",
    //   depth: 1,
    // },
    // {
    //   url: "publishing-your-app-to-the-google-play-store",
    //   name: "Publishing Your App to the Google Play Store",
    //   depth: 1,
    // },
    // {
    //   url: "publishing-your-app-as-a-pwa",
    //   name: "Publishing Your App as a PWA",
    //   depth: 1,
    // },
    // {
    //   url: "sharing-your-project-online",
    //   name: "Sharing Your Project Online",
    //   depth: 1,
    // },
    // { url: "github-commit", name: "GitHub Commit", depth: 1 },
    // { url: "push-notifications", name: "Push Notifications", depth: 1 },
    // {
    //   url: "intro-to-push-notifications",
    //   name: "Intro To Push Notifications",
    //   depth: 1,
    // },
    // {
    //   url: "push-notifications-for-ios",
    //   name: "Push Notifications For iOS",
    //   depth: 1,
    // },
  ];
  const specialItems = [
    "GETTING STARTED",
    "COMPONENTS",
    "STYLING",
    // "Variables",

    "CONNECTING TO DATA",
    "WORKING WITH DATA",
    "DATA INTEGRATIONS",
    "EXPORTING, PUBLISHING & SHARING",
  ];

  useEffect(() => {
    if (listItems) {
      setSelectedData(listItems[0]);
    }
  }, []);

  useEffect(() => {
    if (docId) {
      setDocumentLoading(true);
      setTimeout(() => {
        setTabs(docId);
        setDocumentLoading(false);
      }, 400);
    }
  }, [docId]);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (docId) {
      for (let i = 0; i < listItems?.length; i++) {
        if (listItems[i]?.url === docId) {
          setSelectedData(listItems[i]);
        }
      }
    }
  }, [docId]);

  const navigateForward = () => {
    const currentIndex = listItems.findIndex((item) => item?.url === docId);

    // Find the next index that is not a special item
    let nextIndex = currentIndex + 1;
    while (
      nextIndex < listItems.length &&
      specialItems.includes(listItems[nextIndex].name)
    ) {
      nextIndex++;
    }

    // If nextIndex is within bounds, navigate to the next URL
    if (nextIndex < listItems.length) {
      navigate(`/docs/${listItems[nextIndex]?.url}`);
    }
  };

  const navigateBackward = () => {
    const currentIndex = listItems.findIndex((item) => item?.url === docId);

    // Find the previous index that is not a special item
    let prevIndex = currentIndex - 1;
    while (prevIndex >= 0 && specialItems.includes(listItems[prevIndex].name)) {
      prevIndex--;
    }

    // If prevIndex is within bounds, navigate to the previous URL
    if (prevIndex >= 0) {
      navigate(`/docs/${listItems[prevIndex]?.url}`);
    }
  };
  return (
    <div>
      <DocsNavbar
        listItems={listItems}
        location={location}
        docId={docId}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        tabs={tabs}
        setTabs={setTabs}
        documentLoading={documentLoading}
        setDocumentLoading={setDocumentLoading}
        specialItems={specialItems}
      />
      <MainDocsPage
        listItems={listItems}
        location={location}
        docId={docId}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        tabs={tabs}
        setTabs={setTabs}
        documentLoading={documentLoading}
        setDocumentLoading={setDocumentLoading}
        specialItems={specialItems}
        navigateForward={navigateForward}
        navigateBackward={navigateBackward}
      />
    </div>
  );
};

export default Document;
