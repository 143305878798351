import React, { useState } from "react";
import Select, { components } from "react-select";
import Accordion from "react-bootstrap/Accordion";
import Switch from "react-switch";
import { Height } from "@mui/icons-material";
import { setSelectedFolder } from "../../../../redux/actions/defaultActions";
import { useSelector } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import info_icon from "../../../../assets/Icons/info_icon.svg";
const SpecifieComponent = ({
  typeIcon,
  buttonType,
  OpacityType,
  dateType,
  buttonShapeType,
  radioType,
  buttonRowType,
  condition,
  selectedFolder,
  handleStyleUpdate,
  folderStructure,
  handleWidthValueChange,
  handleBorderColorSelect,
  handleBorderRadiusChange,
  bottomSnap,
  setBottomSnap,
  middleSnap,
  setMiddleSnap,
  topSnap,
  setTopSnap,
  handleSnapChange,
  handleApplyButtonClick,
  handleTextfontColorChange,
}) => {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFontColor, setSelectedFontColor] = useState();

  const ReverseSwitch = ({ checked, labelhandleSwitch, setChecked }) => {
    return (
      <label>
        <Switch
          checked={checked}
          onColor="#3366ff"
          onHandleColor="#2693e6"
          onChange={() => {
            if (checked) {
              labelhandleSwitch("show");
            } else {
              labelhandleSwitch("hide");
            }
            setChecked(!checked);
          }}
          handleDiameter={29}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          className="react-switch"
          id="material-switch"
        />
      </label>
    );
  };

  const text = condition ? "placeholder Text C..." : "Selected Icon Size";

  const colorOptions = [
    { value: "transparent", label: "Transparent" },
    { value: "#ff0000", label: "Red" },
    { value: "#00ff00", label: "Green" },
    { value: "#0000ff", label: "Blue" },
    { value: "#ffffff", label: "white" },
    { value: "#000000", label: "black" },
  ];
  const iconPositionData = [
    { value: "top", label: "top" },
    { value: "bottom", label: "bottom" },
    { value: "left", label: "left" },
    { value: "right", label: "right" },
  ];
  const iconPositionData2 = [
    { value: "top", label: "top" },
    { value: "bottom", label: "bottom" },
  ];

  const handleColorSelect = (color) => {
    setSelectedColor(color ? color.value : null);
    // setSelectedColor(color?.value);
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "background",
      color?.value
    );
  };

  const Showhandle = (type) => {
    handleStyleUpdate(folderStructure, selectedFolder, "showlabel", type);
  };
  const labelhandleSwitch2 = (type) => {
    handleStyleUpdate(folderStructure, selectedFolder, "labelShowHide2", type);
  };
  const labelhandleSwitch = (type) => {
    handleStyleUpdate(folderStructure, selectedFolder, "labelShowHide", type);
  };

  const labelSizeHandler = (e) => {
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "labelSize",
      e.target.value
    );
  };

  const labelColorChange = (data) => {
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "labelColor",
      data?.value
    );
  };

  const datepickerActiveColor = (data) => {
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "activeBorderColor",
      data?.value
    );
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
      borderRadius: "5px",
      width: "200px",
      border: "none",
      minHeight: "30px",
      fontSize: "14px",
      color: "#141A29s",
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: "#1A237E",
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "  rgb(88 103 138 / 56%)" : "#1A237E",
      color: isFocused ? "#fff" : "#fff",
      cursor: "pointer",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "white",
      fontSize: "14px",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "White",
      fontSize: "12px",
    }),
  };

  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <CloseIcon />
      </components.ClearIndicator>
    );
  };

  const handleFontColorSelect = (data) => {
    setSelectedFontColor(data.value);
    handleTextfontColorChange(data.value);
    handleStyleUpdate(folderStructure, selectedFolder, "color", data.value);
  };
  // const toggleActiveColorPicker = (event) => {
  //   event.stopPropagation();
  //   setShowActiveColorPicker(true);
  // };

  return (
    <>
      <div>
        <div className="style-bar layout-bar">
          <Accordion flush defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Component Specific</Accordion.Header>
              <Accordion.Body>
                <div className="main_bar">
                  {selectedFolder?.type === "textField" ||
                  selectedFolder?.type === "text" ? (
                    <div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>Selection Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                                components={{ ClearIndicator }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>Placeholder Text Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {selectedFolder?.type === "tabview" ? (
                    <>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>Active Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={colorOptions}
                                styles={customStyles}
                                components={{ ClearIndicator }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>iconPosition</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={iconPositionData}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="icon_size" style={{ width: "100%" }}>
                          <div className="size-text ">
                            <span>icon size</span>
                          </div>
                          <div className="input_fill">
                            <input type="text" placeholder="auto" />
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>InactiveColor</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>IndicatorColor</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>Tab Bar Position</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={iconPositionData2}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-sltcolor">
                        <div className="leftside_text">
                          <span>Tabs Background Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder=""
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {/* <div className="text-sltcolor">
                    <div className="leftside_text">
                      <span>Underline color</span>
                    </div>

                    <div className="rightside_section">
                      <div className="slt-right">
                        <div className="color_slt">
                          <Select
                            placeholder="Type a Color"
                            options={colorOptions}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="text-sltcolor">
                    <div className="leftside_text">
                      <span>Active Border Color</span>
                    </div>

                    <div className="rightside_section">
                      <div className="slt-right">
                        <div className="color_slt">
                          <Select
                            placeholder="Type a Color"
                            options={colorOptions}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* {
                      <div className="row_direact">
                        <div className="icon-text">
                          <span>Active Border Color</span>
                        </div>
                        <div className="drop-menu">
                          <select>
                            <option value="someOption">auto</option>
                            <option value="otherOption">top</option>
                            <option value="otherOption">bottom</option>
                            <option value="otherOption">center</option>
                          </select>
                        </div>
                      </div>
                    } */}
                  {selectedFolder?.type === "dropdown" ? (
                    <div>
                      <div className="bg_dropDwon">
                        <div className="leftside_text">
                          <span>Dropdown Backdro...</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type or select a Color"
                                options={colorOptions}
                                styles={customStyles}
                                onChange={handleColorSelect}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="br_color_dropDown">
                        <div className="leftside_text">
                          <span>Dropdown Border C...</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                                onChange={handleBorderColorSelect}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="br_radius">
                        <div className="size-text">
                          <span>Dropdown Border Radius</span>
                        </div>
                        <div className="input_fill">
                          <input
                            name="all"
                            type="number"
                            placeholder="auto"
                            value={selectedFolder?.borderRadius?.[0]?.all}
                            onChange={handleBorderRadiusChange}
                          />
                        </div>
                      </div>

                      <div className="br_radius">
                        <div className="size-text">
                          <span>Dropdown Border Width</span>
                        </div>
                        <div className="input_fill">
                          <input
                            name="all"
                            type="text"
                            placeholder="auto"
                            value={selectedFolder?.borderWidth?.[0]?.all}
                            onChange={handleWidthValueChange}
                          />
                        </div>
                      </div>

                      <div className="specific_color_bar">
                        <div className="leftside_text">
                          <span>Dropdwon Text Color </span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                                onChange={handleFontColorSelect}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="specific_color_bar">
                        <div className="leftside_text">
                          <span>Dropdwon Overlay... </span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="specific_color_bar">
                        <div className="leftside_text">
                          <span>Icon Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {/* <div className={condition ? "ph_color" : "slt_color"}>
                    <div className="leftside_text">
                      <span>{text}</span> 
                    </div>
                    <div className="rightside_section">
                      <div className="slt-right">
                        <div className="color_slt"> */}
                  {/* Assuming colorOptions and customStyles are defined elsewhere */}
                  {/* <Select
                            placeholder="Type a Color"
                            options={colorOptions}
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {selectedFolder?.type === "datepicker" && (
                    <>
                      <div className="icon_size">
                        <div className="size-text">
                          <span>Selected Icon Size</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>

                      {/* <div className="row_direact">
                        <div className="icon-text">
                          <span>Appearance</span>
                        </div>
                        <div className="drop-menu">
                          <select>
                            <option value="someOption">solid</option>
                            <option value="otherOption">underli..</option>
                          </select>
                        </div>
                      </div> */}
                    </>
                  )}
                  {selectedFolder?.type === "tabview" && (
                    <>
                      <div className="specific_color_bar">
                        <div className="leftside_text">
                          <span>activeColor</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="icon_size">
                        <div className="size-text">
                          <span>Selected Icon Size</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>

                      <div className="row_direact">
                        <div className="icon-text">
                          <span>Appearance</span>
                        </div>
                        <div className="drop-menu">
                          <select>
                            <option value="someOption">solid</option>
                            <option value="otherOption">underli..</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {typeIcon !== "IconButton" &&
                  buttonShapeType !== "button" &&
                  OpacityType !== "opacityButton" ? (
                    <div className="specific_color_bar">
                      <div className="leftside_text">
                        <span>Color</span>
                      </div>

                      <div className="rightside_section">
                        <div className="slt-right">
                          <div className="color_slt">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {radioType === "radiobutton" &&
                  OpacityType !== "opacityButton" ? (
                    <div className="size-input">
                      <div className="size-text">
                        <span>Size</span>
                      </div>
                      <div className="input_fill">
                        <input type="text" placeholder="auto" />
                      </div>
                    </div>
                  ) : null}
                  {OpacityType !== "opacityButton" ? (
                    <div className="row_direact">
                      <div className="icon-text">
                        <span>Direaction</span>
                      </div>
                      <div className="drop-menu">
                        <select>
                          <option value="someOption">row</option>
                          <option value="otherOption">row-reverse</option>
                        </select>
                      </div>
                    </div>
                  ) : null} */}
                  {/* {radioType === "radiobutton" ? (
                  <div className="size-input">
                    <div className="size-text">
                      <span>Size</span>
                    </div>
                    <div className="input_fill">
                      <input type="text" placeholder="auto" />
                      </div>
                      </div>
                      ) : null} */}
                  {selectedFolder?.type === "button" ? (
                    <div>
                      <div className="act-op">
                        <div className="size-text">
                          <span>Active Opacity</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>
                      <div className="selected_bar">
                        <div className="leftside_text">
                          <span>Unselected Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="size_icon">
                        <div className="size-text">
                          <span>label size</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>

                      <div className="dis-op">
                        <div className="size-text">
                          <span>Disabled Opacity</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>

                      <div className="icon_position">
                        <div className="icon-text">
                          <span>Icon Position</span>
                        </div>
                        <div className="drop-menu">
                          <select>
                            <option value="someOption">left</option>
                            <option value="otherOption">right</option>
                          </select>
                          <select
                            onChange={(e) =>
                              setSelectedCategorie(e.target.value)
                            }
                            style={{
                              width: `${selectedCategorie.length * 16}px`,
                              fontSize: "14px",
                            }}
                          >
                            <option value="someOption">solid</option>
                            <option value="otherOption">underline</option>
                          </select>
                        </div>
                      </div>

                      <div className="icon_size">
                        <div className="size-text">
                          <span>Icon size</span>
                        </div>
                        <div className="input_fill">
                          <input type="text" placeholder="auto" />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {selectedFolder?.type === "datepicker" && (
                    <>
                      <div className="specific_bar">
                        <div className="leftside_text">
                          <span>Active Border Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                                onChange={datepickerActiveColor}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="toggle_bar">
                        <div className="tgl_text">
                          <span>Hide Label</span>
                        </div>
                        <div className="toggle_right">
                          <ReverseSwitch
                            labelhandleSwitch={labelhandleSwitch}
                            checked={checked}
                            setChecked={setChecked}
                          />
                        </div>
                      </div>

                      <div className="specific_bar">
                        <div className="leftside_text">
                          <span>label Color</span>
                        </div>

                        <div className="rightside_section">
                          <div className="slt-right">
                            <div className="color_slt">
                              <Select
                                placeholder="Type a Color"
                                options={colorOptions}
                                styles={customStyles}
                                onChange={labelColorChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="icon_size">
                        <div className="size-text">
                          <span>Label Size</span>
                        </div>
                        <div className="input_fill">
                          <input
                            type="text"
                            value={selectedFolder?.labelSize}
                            onChange={labelSizeHandler}
                          />
                        </div>
                      </div>

                      <div className="icon_bar">
                        <div className="icon-text">
                          <span>Left icon mode</span>
                        </div>
                        <div className="drop-menu">
                          <select>
                            <option value="someOption">Inset</option>
                            <option value="otherOption">Outset</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  {/* 
                  {radioType === "radiobutton" &&
                  OpacityType !== "opacityButton" ? (
                    <div className="size-input">
                      <div className="size-text">
                        <span>Size</span>
                      </div>
                      <div className="input_fill">
                        <input type="text" placeholder="auto" />
                      </div>
                    </div>
                  ) : null}
                  {OpacityType !== "opacityButton" && (
                    <>
                      <div className="row_direact">
                        <div className="icon-text">
                          <span>Direaction</span>
                        </div>
                        <div className="drop-menu">
                          <select
                            onChange={(e) =>
                              setSelectedCategorie(e.target.value)
                            }
                            style={{
                              width: `${selectedCategorie.length * 16}px`,
                              fontSize: "14px",
                            }}
                          >
                            <option value="someOption">row</option>
                            <option value="otherOption">row-reverse</option>
                          </select>
                        </div>

                        <div className="icon_bar">
                          <div className="icon-text">
                            <span>Left icon mode</span>
                          </div>
                          <div className="drop-menu">
                            <select>
                              <option value="someOption">Inset</option>
                              <option value="otherOption">Outset</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}
                  {selectedFolder?.type === "textField" && (
                    <div className={condition ? "ph_color" : "slt_color"}>
                      <div className="leftside_text">
                        <span>{text}</span>
                      </div>
                      <div className="rightside_section">
                        <div className="slt-right">
                          <div className="color_slt">
                            {/* Assuming colorOptions and customStyles are defined elsewhere */}
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {OpacityType !== "opacityButton" ? (
                    <div className="size_icon">
                      <div className="size-text">
                        <span>label size</span>
                      </div>
                      <div className="input_fill">
                        <input type="text" placeholder="auto" />
                      </div>
                    </div>
                  ) : null}
                  {OpacityType !== "opacityButton" ? (
                    <div className="toggle_bar">
                      <div className="tgl_text">
                        <span>Hide Label</span>
                      </div>
                      <div className="toggle_right">
                        <ReverseSwitch
                          checked={checked}
                          setChecked={setChecked}
                        />
                      </div>
                    </div>
                  ) : null}
                  {/* {OpacityType === "opacityButton" ? (
                    <div className="dis-op">
                      <div className="size-text">
                        <span>Disabled Opacity</span>
                      </div>
                      <div className="input_fill">
                        <input type="text" placeholder="auto" />
                      </div>
                    </div>
                  ) : null} */}
                  {/* {OpacityType === "opacityButton" ? (
                    <div className="icon_position">
                      <div className="icon-text">
                        <span>Icon Position</span>
                      </div>
                      <div className="drop-menu">
                        <select
                          onChange={(e) => setSelectedCategorie(e.target.value)}
                          style={{
                            width: `${selectedCategorie.length * 16}px`,
                            fontSize: "14px",
                          }}
                        >
                          <option value="someOption">left</option>
                          <option value="otherOption">right</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="specific_bar">
                      <div className="leftside_text">
                        <span>Selection Color</span>
                      </div>

                      <div className="rightside_section">
                        <div className="slt-right">
                          <div className="color_slt">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {OpacityType === "opacityButton" ? (
                    <div className="icon_size">
                      <div className="size-text">
                        <span>Icon size</span>
                      </div>
                      <div className="input_fill">
                        <input type="text" placeholder="auto" />
                      </div>
                    </div>
                  ) : (
                    <div className="icon_bar">
                      <div className="icon-text">
                        <span>Left icon mode</span>
                      </div>
                      <div className="drop-menu">
                        <select
                          onChange={(e) => setSelectedCategorie(e.target.value)}
                          style={{
                            width: `${selectedCategorie.length * 16}px`,
                            fontSize: "14px",
                          }}
                        >
                          <option value="someOption">Inset</option>
                          <option value="otherOption">Outset</option>
                        </select>
                      </div>
                    </div>
                  )}
                  <div className="handle-color-1">
                    <div className="leftside_text">
                      <span>Handler color</span>
                      <img src={info_icon} alt="" />
                    </div>
                    <Select
                      options={colorOptions}
                      name="colors"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      styles={customStyles}
                    />
                  </div>
                  <div className="position-1">
                    <div className="icon-text">
                      <span>Initial snap </span>
                      <img src={info_icon} alt="" />
                    </div>
                    <div className="drop-menu">
                      <select
                        onChange={(e) => setSelectedCategorie(e.target.value)}
                        style={{
                          width: `${selectedCategorie.length * 12}px`,
                          fontSize: "14px",
                        }}
                      >
                        <option value="someOption">top</option>
                        <option value="otherOption">middle</option>
                        <option value="otherOption">bottom</option>
                      </select>
                    </div>
                  </div> */}
                  {selectedFolder?.type === "bottomSheets" ? (
                    <div className="bottom-sheets">
                      <div className="inner">
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Border Color</p>
                          </div>
                          <div className="right_sec">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                              onChange={handleBorderColorSelect}
                            />
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Border Width</p>
                          </div>
                          <div className="right_sec">
                            <input
                              type="number"
                              placeholder="auto"
                              value={selectedFolder?.borderWidth?.[0]?.all}
                              name="all"
                              onChange={handleWidthValueChange}
                            />
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Bottom snap position</p>
                          </div>
                          <div className="right_sec">
                            <div className="unit_sec">
                              <input
                                type="number"
                                placeholder="auto"
                                snap={bottomSnap}
                                onChange={(e) => handleSnapChange(e, "bottom")}
                              />
                              <select
                                value=""
                                onChange={"handleWidthUnitChange"}
                              >
                                <option className="no_brdr" value="px">
                                  px
                                </option>
                                <option className="no_brdr" value="%">
                                  %
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="border-color">
                          <div className="left_sec">
                            <p>Handle Color</p>
                          </div>
                          <div className="right_sec">
                            <Select
                              placeholder="Type a Color"
                              options={colorOptions}
                              styles={customStyles}
                            />
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Initial snap position</p>
                          </div>
                          <div className="right_sec snap_pos">
                            <select value="">
                              <option className="no_brdr" value="px">
                                Bottom
                              </option>
                              <option className="no_brdr" value="%">
                                Top
                              </option>
                              <option className="no_brdr" value="%">
                                Middle
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="border-color">
                          <div className="left_sec">
                            <p>Middle snap position</p>
                          </div>
                          <div className="right_sec">
                            <div className="unit_sec">
                              <input
                                type="number"
                                placeholder="auto"
                                snap={middleSnap}
                                value={selectedFolder?.snaps?.[1]?.middleSnap}
                                onChange={(e) => handleSnapChange(e, "middle")}
                              />
                              <select
                                value="50"
                                onChange={"handleWidthUnitChange"}
                              >
                                <option className="no_brdr" value="px">
                                  px
                                </option>
                                <option className="no_brdr" value="%">
                                  %
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="toggle_bar">
                          <div className="tgl_text">
                            <span>Show handle</span>
                          </div>
                          <div className="toggle_right">
                            <ReverseSwitch
                              labelhandleSwitch={Showhandle}
                              checked={checked2}
                              setChecked={setChecked2}
                            />
                          </div>
                        </div>

                        <div className="toggle_bar">
                          <div className="tgl_text">
                            <span>Show Vertical Scroll Indicator</span>
                          </div>
                          <div className="toggle_right">
                            <ReverseSwitch
                              labelhandleSwitch={labelhandleSwitch2}
                              checked={checked3}
                              setChecked={setChecked3}
                            />
                          </div>
                        </div>
                        {/* <div className="border-color">
                          <div className="left_sec">
                            <p>Show handle</p>
                          </div>
                          <div className="right_sec">
                            <div className="unit_sec2">
                              <Switch
                                onChange={handleChange}
                                checked={checked}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p className="first_txt"></p>
                          </div>
                          <div className="right_sec">
                            <div className="unit_sec2">
                              <Switch
                                onChange={handleChange}
                                checked={checked}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Top border radius</p>
                          </div>
                          <div className="right_sec">
                            <input
                              name="all"
                              type="number"
                              placeholder="auto"
                              value={selectedFolder?.borderRadius?.[0]?.all}
                              onChange={handleBorderRadiusChange}
                            />
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Top inset</p>
                          </div>
                          <div className="right_sec">
                            <input type="number" placeholder="auto" />
                          </div>
                        </div>
                        <div className="border-color">
                          <div className="left_sec">
                            <p>Top snap position</p>
                          </div>
                          <div className="right_sec">
                            <div className="unit_sec">
                              <input
                                type="number"
                                placeholder="auto"
                                snap={topSnap}
                                onChange={(e) => handleSnapChange(e, "top")}
                              />
                              <select
                                value=""
                                onChange={"handleWidthUnitChange"}
                              >
                                <option className="no_brdr" value="px">
                                  px
                                </option>
                                <option className="no_brdr" value="%">
                                  %
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default SpecifieComponent;
