// import React, { useState, useEffect, useRef } from "react";
// import UpdateMyValues from "../../../../common/customHooks/UpdateMyValues";

// const TextArea = ({
//   item,
//   selectedFolder,
//   setTextValue,
//   textValue,
//   handleKeyDown,
//   setSelectedFolderAction,
//   isDraftPreview,
//   readOnly,
//   myApiKeys,
//   index,
//   setFolderStructureAction,
//   folderStructure,
// }) => {
//   const inputRef = useRef();
//   const [showTooltip, setShowTooltip] = useState(false);
//   const tooltipRef = useRef(null);
//   const [value, setValue] = useState({ canvas_text: "Enter your Text" });

//   useEffect(() => {
//     adjustRows();
//   }, [value]);

//   const { handleStyleUpdate } = UpdateMyValues(
//     setFolderStructureAction,
//     setSelectedFolderAction
//   );

//   useEffect(() => {
//     if (inputRef.current) {
//       inputRef.current.addEventListener("paste", handlePaste);
//     }
//     return () => {
//       if (inputRef.current) {
//         inputRef.current.removeEventListener("paste", handlePaste);
//       }
//     };
//   }, []);

//   const handlePaste = (event) => {
//     // Delay needed to ensure the value has been updated after paste
//     setTimeout(adjustRows, 0);
//   };

//   const handleTextChange = (event) => {
//     const newTextValue = event.target.value;
//     setValue({ ...newTextValue, [event.target.name]: newTextValue });
//     // setTextValue(newTextValue);
//     handleStyleUpdate(
//       folderStructure,
//       selectedFolder,
//       "canvas_text",
//       newTextValue
//     );
//   };

//   const onblurHandle = (e) => {
//     const newTextValue = e.target.value;
//     setTextValue(newTextValue);
//   };

//   const adjustRows = () => {
//     const textarea = inputRef.current;
//     if (textarea) {
//       textarea.style.height = "auto"; // Reset height to auto to calculate the new height
//       textarea.style.height = textarea.scrollHeight + "px"; // Set the height to the scrollHeight
//     }
//   };

//   const onDataSelectHandler = (e) => {
//     e.stopPropagation(); // Prevent event propagation
//     inputRef.current.focus(); // Ensure textarea retains focus
//     // setSelectedFolderAction(item);
//   };

//   const marginStyle = item.margin
//     ? {
//         margin: item.margin[0].all + item.margin[0].unit,
//         marginLeft: item.margin[1].left + item.margin[1].unit,
//         marginTop: item.margin[2].top + item.margin[2].unit,
//         marginRight: item.margin[3].right + item.margin[3].unit,
//         marginBottom: item.margin[4].bottom + item.margin[4].unit,
//       }
//     : {};
//   const paddingStyle = item.padding
//     ? {
//         padding: item.padding[0].all + item.padding[0].unit,
//         paddingLeft: item.padding[1].left + item.padding[1].unit,
//         paddingTop: item.padding[2].top + item.padding[2].unit,
//         paddingRight: item.padding[3].right + item.padding[3].unit,
//         paddingBottom: item.padding[4].bottom + item.padding[4].unit,
//       }
//     : {};
//   const radiusStyle = item.borderRadius
//     ? {
//         borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
//         borderTopLeftRadius:
//           item.borderRadius[1].topLeft + item.borderRadius[1].unit,
//         borderTopRightRadius:
//           item.borderRadius[2].topRight + item.borderRadius[2].unit,
//         borderBottomLeftRadius:
//           item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
//         borderBottomRightRadius:
//           item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
//       }
//     : {};
//   const borderWidthStyle = item.borderWidth
//     ? {
//         borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
//         borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1].unit,
//         borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
//         borderRightWidth: item.borderWidth[3].right + item.borderWidth[3].unit,
//         borderBottomWidth:
//           item.borderWidth[4].bottom + item.borderWidth[4].unit,
//       }
//     : {};

//   const handleTextareaClick = (e) => {
//     e.stopPropagation();
//     inputRef.current.focus();
//     setShowTooltip(true);
//     if (selectedFolder?.type !== "text") {
//       setSelectedFolderAction(item);
//     }
//   };

//   const handleTooltipClose = () => {
//     setShowTooltip(false);
//   };

//   const handleOutsideClick = (e) => {
//     if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
//       setShowTooltip(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleOutsideClick);

//     return () => {
//       document.removeEventListener("mousedown", handleOutsideClick);
//     };
//   }, []);

//   const inputRect = inputRef.current?.getBoundingClientRect();
//   const tooltipLeft = inputRect ? inputRect.left : "50%";

//   return (
//     <div style={{ position: "relative" }} key={`items-${index}`}>
//       <input
//         id={item.id}
//         name="canvas_text"
//         ref={inputRef}
//         onKeyDown={(e) => {
//           handleKeyDown(e);
//           if (e.key === "Enter") {
//             adjustRows();
//           }
//         }}
//         className={
//           selectedFolder?.id === item?.id ? "selectInput-box" : "input-box"
//         }
//         style={{
//           resize: "none",
//           zIndex: "10",
//           width: "347px",
//           cursor: isDraftPreview ? "pointer" : "default", // Set cursor style dynamically
//           color: item.id ? item.color : null,
//           fontSize: item.id ? `${item.fontSize}px` : "12px",
//           lineHeight: item.id ? `${item.lineHeight}px` : "12px",
//           letterSpacing: item.id ? `${item.letterSpacing}px` : null,
//           fontFamily: item.id ? item.fontFamily : null,
//           textAlign: item.id ? item.textAlign : null,
//           fontWeight:
// item.id && item.fontWeight.length > 0
//   ? item.fontWeight.find((obj) => obj.selectedFont)?.Weight
//   : null,
//           textDecoration: item.id ? item.textdecoration : null,
//           borderColor: item.id ? item.borderColor : null,
//           borderStyle: item.id ? item.borderStyle : null,
//           textTransform: item.id ? item.textTransform : null,
//           ...marginStyle,
//           ...paddingStyle,
//           ...borderWidthStyle,
//           ...radiusStyle,
//         }}
//         rows={1}
//         onChange={handleTextChange}
//         value={value?.canvas_text}
//         // onBlur={(e) => onblurHandle(e)}
//         onClick={handleTextareaClick}
//         readOnly={readOnly}
//       />
//     </div>
//   );
// };

// export default TextArea;

import React, { useState, useEffect, useRef } from "react";
import UpdateMyValues from "../../../../common/customHooks/UpdateMyValues";

const TextArea = ({
  item,
  selectedFolder,
  setTextValue,
  textValue,
  handleKeyDown,
  setSelectedFolderAction,
  isDraftPreview,
  readOnly,
  myApiKeys,
  index,
  setFolderStructureAction,
  folderStructure,
}) => {
  const inputRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);
  const [value, setValue] = useState(item.canvas_text || "Enter your Text");

  useEffect(() => {
    adjustRows();
  }, [value]);

  const { handleStyleUpdate } = UpdateMyValues(
    setFolderStructureAction,
    setSelectedFolderAction
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("paste", handlePaste);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("paste", handlePaste);
      }
    };
  }, []);

  const handlePaste = (event) => {
    // Delay needed to ensure the value has been updated after paste
    setTimeout(adjustRows, 0);
  };

  const handleTextChange = (event) => {
    const newTextValue = event.target.value;
    setValue(newTextValue);
  };

  const onblurHandle = (e) => {
    const newTextValue = e.target.value;
    handleStyleUpdate(
      folderStructure,
      selectedFolder,
      "canvas_text",
      newTextValue
    );
  };

  const adjustRows = () => {
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to calculate the new height
      textarea.style.height = textarea.scrollHeight + "px"; // Set the height to the scrollHeight
    }
  };

  const onDataSelectHandler = (e) => {
    e.stopPropagation(); // Prevent event propagation
    inputRef.current.focus(); // Ensure textarea retains focus
    // setSelectedFolderAction(item);
  };

  const marginStyle = item.margin
    ? {
        margin: item.margin[0].all + item.margin[0].unit,
        marginLeft: item.margin[1].left + item.margin[1].unit,
        marginTop: item.margin[2].top + item.margin[2].unit,
        marginRight: item.margin[3].right + item.margin[3].unit,
        marginBottom: item.margin[4].bottom + item.margin[4].unit,
      }
    : {};
  const paddingStyle = item.padding
    ? {
        padding: item.padding[0].all + item.padding[0].unit,
        paddingLeft: item.padding[1].left + item.padding[1].unit,
        paddingTop: item.padding[2].top + item.padding[2].unit,
        paddingRight: item.padding[3].right + item.padding[3].unit,
        paddingBottom: item.padding[4].bottom + item.padding[4].unit,
      }
    : {};
  const radiusStyle = item.borderRadius
    ? {
        borderRadius: item.borderRadius[0].all + item.borderRadius[0].unit,
        borderTopLeftRadius:
          item.borderRadius[1].topLeft + item.borderRadius[1].unit,
        borderTopRightRadius:
          item.borderRadius[2].topRight + item.borderRadius[2].unit,
        borderBottomLeftRadius:
          item.borderRadius[3].bottomLeft + item.borderRadius[3].unit,
        borderBottomRightRadius:
          item.borderRadius[4].bottomRight + item.borderRadius[4].unit,
      }
    : {};
  const borderWidthStyle = item.borderWidth
    ? {
        borderWidth: item.borderWidth[0].all + item.borderWidth[0].unit,
        borderLeftWidth: item.borderWidth[1].left + item.borderWidth[1].unit,
        borderTopWidth: item.borderWidth[2].top + item.borderWidth[2].unit,
        borderRightWidth: item.borderWidth[3].right + item.borderWidth[3].unit,
        borderBottomWidth:
          item.borderWidth[4].bottom + item.borderWidth[4].unit,
      }
    : {};

  const handleTextareaClick = (e) => {
    e.stopPropagation();
    inputRef.current.focus();
    setShowTooltip(true);
    if (selectedFolder?.id !== item?.id) {
      setSelectedFolderAction(item);
    }
  };

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  const handleOutsideClick = (e) => {
    if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const inputRect = inputRef.current?.getBoundingClientRect();
  const tooltipLeft = inputRect ? inputRect.left : "50%";

  return (
    <div style={{ position: "relative" }} key={`items-${index}`}>
      <input
        id={item.id}
        name="canvas_text"
        ref={inputRef}
        onKeyDown={(e) => {
          handleKeyDown(e);
          if (e.key === "Enter") {
            adjustRows();
          }
        }}
        className={
          selectedFolder?.id === item?.id ? "selectInput-box" : "input-box"
        }
        style={{
          resize: "none",
          zIndex: "10",
          width: "347px",
          cursor: isDraftPreview ? "pointer" : "default",
          color: item.id ? item.color : null,
          fontSize: item.id ? `${item.fontSize}px` : "12px",
          lineHeight: item.id ? `${item.lineHeight}px` : "12px",
          letterSpacing: item.id ? `${item.letterSpacing}px` : null,
          fontFamily: item.id ? item.fontFamily : null,
          textAlign: item.id ? item.textAlign : null,
          fontWeight:
            item.id && item.fontWeight.length > 0
              ? item.fontWeight.find((obj) => obj.selectedFont)?.Weight
              : null,
          textDecoration: item.id ? item.textdecoration : null,
          borderColor: item.id ? item.borderColor : null,
          borderStyle: item.id ? item.borderStyle : null,
          textTransform: item.id ? item.textTransform : null,
          ...marginStyle,
          ...paddingStyle,
          ...borderWidthStyle,
          ...radiusStyle,
          width: value?.length > 15 ? "auto" : `${value?.length * 14}px`,
        }}
        rows={1}
        onChange={handleTextChange}
        value={value}
        onBlur={(e) => onblurHandle(e)}
        onClick={handleTextareaClick}
        readOnly={readOnly}
      />
    </div>
  );
};

export default TextArea;
